import { Fragment, useEffect, useState } from 'react';
// import Page from '../../components/Page';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import AsyncSelect from 'react-select/async';
import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Box,
  Grid,
  Button,
  IconButton,
  colors,
  CardHeader,
} from '@mui/material';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import Select from 'react-select';
import { addEditSeriesSchema } from 'src/utils/validationSchemas';
import { addEditApi, listApi } from 'src/apis/admin';
import { APIs } from 'src/apis';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'src/router/routes';
import { toast } from 'react-toastify';
// import { handleRewardType } from '../../utils/helper';
import { DATE_FORMAT_TIME } from 'src/contants/contants';
import BackButton from 'src/components/backButton';
import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { handleRewardType } from 'src/utils/helper';

const emptyData = {
  positions: [],
  rewardId: '',
  expiryDate: '',
  redeemTime: '',
  winningPoints: '',
};
const initialValues = {
  name: '',
  endDateTime: '',
  numberOfPositions: '',
  rowData: [emptyData],
};

const labelStyle = {
  fontSize: '12px'
}

export default function AddEditSeries({ isEdit }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [rewardData, setRewardData] = useState();
  const [positionOptions, setPositionOptions] = useState([]);
  useEffect(() => {
    if (id) {
      setLoading(true);
      listApi(APIs.LIST_SERIES, { seriesId: id })
        .then((result) => {
          const data = cloneDeep(result?.data);
          const modifyContestData = data?.contestIds?.map((item, index) => {
            const contestData = {
              label: (
                <div className="optionStyles" key={index}>
                  <img src={item?.imageLink} height="30px" width="30px" />
                  <div className="selectText">
                    {item?.name} | {moment(item?.startDateTime).local().format(DATE_FORMAT_TIME)}
                  </div>
                </div>
              ),
              value: item?.id,
            };
            return contestData;
          });
          const positionData = data?.data?.map((row, index) => {
            const positions = row?.positions?.map((num) => {
              return { label: num, value: num };
            });
            let typealpha = handleRewardType(row?.rewardId?.type);
            const rewardId = {
              label: (
                <div className="optionStyles">
                  <img src={row?.rewardId?.imageLink} height="30px" width="30px" />
                  <div className="selectText">
                    {row?.rewardId?.title} | {row?.rewardId?.shortDescription} | {typealpha} | {row?.rewardId?.price}$
                  </div>
                </div>
              ),
              name: `${row?.rewardId?.title | row?.rewardId?.shortDescription | typealpha | row?.rewardId?.price}$`,
              value: row?.rewardId?.id,
            };

            return {
              rewardId,
              expiryDate: new Date(row?.expiryDate),
              redeemTime: row?.redeemTime,
              winningPoints: row?.winningPoints,
              positions,
            };
          });
          let updateData = {
            name: data?.name,
            endDateTime: new Date(data?.endDateTime),
            contestIds: modifyContestData,
            numberOfPositions: data?.data?.[0]?.numberOfPositions,
            partnerId: {
              label: (
                <div className="optionStyles">
                  <img src={data?.partnerData?.profileImage} height="30px" width="30px" />
                  <div className="selectText">{data?.partnerData.name}</div>
                </div>
              ),
              value: data?.partnerId,
            },
            rowData: positionData.length
              ? positionData
              : [
                {
                  rewardId: '',
                  expiryDate: '',
                  redeemTime: '',
                  winningPoints: '',
                  positions: [],
                },
              ],
          };
          setRewardData(updateData);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  const handleFormSubmit = (formikdata) => {
    const DATA = formikdata?.rowData?.map((item) => ({
      positions: item?.positions.map(({ value }) => value),
      rewardId: item?.rewardId?.value,
      expiryDate: item?.expiryDate,
      redeemTime: item?.redeemTime,
      winningPoints: item?.winningPoints,
      numberOfPositions: formikdata?.numberOfPositions,
    }));

    let ApiPayload = {
      name: formikdata?.name,
      contestIds: formikdata?.contestIds?.map((contest) => contest?.value),
      partnerId: formikdata?.partnerId?.value,
      endDateTime: formikdata?.endDateTime,
      data: DATA,
    };
    if (id) {
      ApiPayload.id = id;
    }

    addEditApi(APIs.ADD_EDIT_SERIES, ApiPayload).then(
      () => toast.success('Added Successfully'),
      navigate(ROUTES.SERIES)
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: rewardData || initialValues,
    validationSchema: addEditSeriesSchema,
    onSubmit: handleFormSubmit,
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const partnerOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_PARTNERS, { search: inputValue }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: (
          <div className="optionStyles">
            <img src={item?.profileImage} height="30px" width="30px" />
            <div className="selectText">{item.name}</div>
          </div>
        ),
        value: item?.id,
      }));

      resolve(filter);
    });
  };

  const loadPartners = debounce(partnerOptions, 300);
  const promiseContestDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_CONTESTS, { search: inputValue }).then((result) => {
      const filter = result?.data.map((item) => ({
        label: (
          <div className="optionStyles">
            <img src={item?.imageLink} height="30px" width="30px" />
            <div className="selectText">
              {item.name} | {moment(item.startDateTime).local().format(DATE_FORMAT_TIME)}
            </div>
          </div>
        ),
        value: item.id,
      }));
      resolve(filter);
    });
  };
  const loadContests = debounce(promiseContestDataOptions, 300);

  const promiseReawrdDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_REWARD_TYPES, { search: inputValue }).then((result) => {
      const filter = result?.data.map((item) => {
        let typealpha = handleRewardType(item?.type);
        let option = {
          label: (
            <div className="optionStyles">
              <img src={item?.imageLink} height="30px" width="30px" />
              <div className="selectText">
                {item?.title} | {item?.shortDescription} | {typealpha} | {item?.price}$
              </div>
            </div>
          ),
          name: `${item?.title | item?.shortDescription | typealpha | item?.price}$`,
          value: item?.id,
        };
        return option;
      });
      resolve(filter);
    });
  };

  const loadRewardTypes = debounce(promiseReawrdDataOptions, 300);
  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 55,
        border: '',
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2,
      };
    },
  };
  useEffect(() => {
    let option = [];
    for (let i = 1; i <= values.numberOfPositions; i++) {
      option.push({
        label: i,
        value: i,
      });
    }

    setPositionOptions(option);
  }, [values?.numberOfPositions]);

  return (
    <Page title="Contest" loading={loading}>
      <Container>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                {isEdit ? 'Edit Series' : 'Add Series'}
              </Typography>
              <div>
                <BackButton onClick={() => navigate(ROUTES.SERIES)} />
                <Button variant="contained" type="submit" startIcon={<Iconify icon="eva:save-outline" />}>
                  Save Series
                </Button>
              </div>
            </Stack>

            <Card>
              <CardHeader title="Series Details" />
              <Box sx={{ flexGrow: 1, padding: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        value={values?.endDateTime}
                        label="Series End Date"
                        onChange={(date) => setFieldValue('endDateTime', date)}
                        // disablePast={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={Boolean(touched.endDateTime && errors.endDateTime)}
                            helperText={touched.endDateTime && errors.endDateTime}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={labelStyle}>Selected Partner</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      placeholder="Select Partner"
                      styles={customStyles}
                      loadOptions={loadPartners}
                      value={values?.partnerId}
                      onChange={(data) => {
                        setFieldValue('partnerId', data);
                      }}
                    />
                    {Boolean(touched.partnerId && errors.partnerId) && (
                      <Typography variant="caption" display="block" gutterBottom color={'red'}>
                        {errors.partnerId}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label style={labelStyle}>Contests</label>
                    <AsyncSelect
                      cacheOptions
                      isMulti
                      defaultOptions
                      placeholder="Contests"
                      styles={customStyles}
                      loadOptions={loadContests}
                      value={values?.contestIds}
                      onChange={(data) => {
                        setFieldValue('contestIds', data);
                      }}
                    />
                    {Boolean(touched.contestIds && errors.contestIds) && (
                      <Typography variant="caption" display="block" gutterBottom color={'red'}>
                        {errors.contestIds}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Card>

            <Card style={{ marginTop: '20px' }}>
              <CardHeader title="Reward Details" />
              <Box sx={{ flexGrow: 1, padding: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Total Number of Reward Positions"
                      {...getFieldProps('numberOfPositions')}
                      value={values?.numberOfPositions}
                      error={Boolean(touched.numberOfPositions && errors.numberOfPositions)}
                      helperText={touched.numberOfPositions && errors.numberOfPositions}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} />
                  <FieldArray
                    name="rowData"
                    render={(arrayHelpers) => (
                      <>
                        {values?.rowData?.map((item, index) => (
                          <Fragment key={index}>
                            <Grid item xs={6} md={6}>
                              <label style={labelStyle}>Selected Positions</label>
                              <Select
                                name={`rowData.${index}.positions`}
                                isMulti
                                placeholder="Select Positions"
                                options={[{ value: positionOptions, label: 'All' }, ...positionOptions]}
                                styles={customStyles}
                                value={values?.rowData?.[index]?.positions}
                                onChange={(data) => {
                                  data?.find((option) => option?.label === 'All')
                                    ? setFieldValue(`rowData.${index}.positions`, positionOptions)
                                    : setFieldValue(`rowData.${index}.positions`, data);
                                }}
                              />
                              {errors?.rowData?.[index]?.positions && touched?.rowData?.[index]?.positions && (
                                <div className="field-error">{errors?.rowData?.[index]?.positions}</div>
                              )}
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <label style={labelStyle}>Reward Selected</label>
                              <AsyncSelect
                                name={`rowData.${index}.rewardId`}
                                cacheOptions
                                defaultOptions
                                placeholder="Select Reward Title/Short Description"
                                loadOptions={loadRewardTypes}
                                value={values?.rowData[index]?.rewardId}
                                styles={customStyles}
                                isClearable
                                onChange={(data) => {
                                  setFieldValue(`rowData.${index}.rewardId`, data);
                                }}
                              />
                              {errors?.rowData?.[index]?.rewardId && touched?.rowData?.[index]?.rewardId && (
                                <div className="field-error">{errors?.rowData?.[index]?.rewardId}</div>
                              )}
                            </Grid>
                            <Grid item xs={4} md={4}>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                  value={values?.rowData[index].expiryDate}
                                  label="Expiry date"
                                  inputFormat="MM/dd/yyyy"
                                  onChange={(date) => setFieldValue(`rowData.${index}.expiryDate`, date)}
                                  disablePast={true}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      error={Boolean(
                                        touched?.rowData?.[index]?.expiryDate && errors?.rowData?.[index]?.expiryDate
                                      )}
                                      helperText={
                                        touched?.rowData?.[index]?.expiryDate && errors?.rowData?.[index]?.expiryDate
                                      }
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={4} md={2}>
                              <TextField
                                fullWidth
                                value={values?.rowData[index]?.redeemTime}
                                type="number"
                                name={`rowData.${index}.redeemTime`}
                                label={index === 0 ? 'Redeem Time (in Mins)' : ''}
                                placeholder={
                                  index === 0 ? '1. Redeem Time (in Mins)' : `${index + 1}. Redeem Time (in Mins)`
                                }
                                onChange={(e) => setFieldValue(`rowData.${index}.redeemTime`, parseInt(e.target.value))}
                                error={Boolean(
                                  touched?.rowData?.[index]?.redeemTime && errors?.rowData?.[index]?.redeemTime
                                )}
                                helperText={
                                  touched?.rowData?.[index]?.redeemTime && errors?.rowData?.[index]?.redeemTime
                                }
                              />
                            </Grid>
                            <Grid item xs={3} md={3}>
                              <TextField
                                fullWidth
                                value={values?.rowData[index]?.winningPoints}
                                type="number"
                                name={`rowData.${index}.winningPoints`}
                                label={index === 0 ? 'Winning Points' : ''}
                                placeholder={index === 0 ? '1. Winning Points ' : `${index + 1}. Winning Points`}
                                onChange={(e) =>
                                  setFieldValue(`rowData.${index}.winningPoints`, parseInt(e.target.value))
                                }
                                error={Boolean(
                                  touched?.rowData?.[index]?.winningPoints && errors?.rowData?.[index]?.winningPoints
                                )}
                                helperText={
                                  touched?.rowData?.[index]?.winningPoints && errors?.rowData?.[index]?.winningPoints
                                }
                              />
                            </Grid>

                            <Grid item xs={2} md={2}>
                              {values?.rowData?.length !== 1 && (
                                <IconButton
                                  size="large"
                                  sx={{ ml: 2, color: colors.red[500] }}
                                  onClick={() => {
                                    return arrayHelpers.remove(index);
                                  }}
                                >
                                  <Iconify icon="eva:minus-square-fill" />
                                </IconButton>
                              )}
                              <Grid item xs={2} md={2}>
                                <IconButton
                                  size="large"
                                  sx={{ ml: 2, color: 'primary.main' }}
                                  onClick={() => {
                                    arrayHelpers.insert(index + 1, emptyData);
                                  }}
                                >
                                  <Iconify icon="eva:plus-square-fill" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Fragment>
                        ))}
                      </>
                    )}
                  />
                </Grid>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

              </Box>
            </Card>


          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
