import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Dialog,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Grid,
} from '@mui/material';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import { UserListToolbar } from '../../sections/@dashboard/user';
import AsyncSelect from 'react-select/async';
import { addEditApi, listApi } from 'src/apis/admin';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { ROUTES } from 'src/router/routes';
import { APIs } from '../../apis';
import MoreMenu from 'src/components/table/MoreMenu';
import { confirmAlert } from 'react-confirm-alert';
import CloneRewardModal from './cloneRewardModal';
import WinnerList from './winnerList';
import { DATE_FORMAT, DATE_FORMAT_TIME } from 'src/contants/contants';
import QuiModal from './quizModal';
import { toast } from 'react-toastify';
import ImageModal from '../openAI/questionBankList/imageModal';
import { openLinkNewTab } from 'src/utils/helper';
import SpeicalQuizFlowModal from './specialQuizFlow';
import { useSelector } from 'react-redux';
import ContestStats from './contestStatsModal';

const MENU_CONSTANT = {
  CLONE: 'Clone',
  CONTEST_STATUS: 'CStatus',
  QUIZ_STATUS: 'QStatus',
  ASSIGN_REWARD: 'Reward',
  NOTIFICATIONS: 'Notification',
};

const TABLE_HEAD = [
  { id: '' },
  { id: 'name', label: 'Contest Name', alignRight: false },
  { id: 'contestType', label: 'Contest Type', alignRight: false },
  { id: 'status', label: 'Contest Status', alignRight: false },
  { id: 'quizActive', label: 'Quiz Status', alignRight: false },
  { id: 'rewardActive', label: 'Rewards Assigned', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'startDateTime', label: 'Start DateTime', alignRight: false },
  { id: 'endDateTime', label: 'End DateTime', alignRight: false },
  { id: 'teams', label: 'Teams', alignRight: false },
  { id: 'scheduleDate', label: 'Schedule Active Time', alignRight: false },
  { id: 'postOnTwitter', label: 'Post-On Twitter', alignRight: false },
  { id: 'weekName', label: 'Week Name', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'isAgeNeeded', label: 'Is Age Needed?', alignRight: false },
  { id: 'minimumAge', label: 'Minimum Age', alignRight: false },
  { id: 'termsLink', label: 'TermsLink', alignRight: false },
  { id: 'rulesLink', label: 'Rules Link', alignRight: false },
  { id: 'createdAt', label: 'Created at', alignRight: false },
];

export default function Contests() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cloneModalVisible, setCloneModalVisible] = useState(false);
  const [selectedContest, setSelectedContest] = useState();
  const [partnerFilterSelect, setPartnerFilterSelect] = useState();
  const [openWinnerList, setOpenWinnerList] = useState(false);
  const [openContestStats, setContestStats] = useState(false);
  const [openSpecialQuizFlow, setOpenSpecialQuizFlow] = useState(false);
  const [openQuizModal, setOpenQuizModal] = useState(false);
  const [selectedGame, setSelectedGame] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);
  const user = useSelector((state) => state.auth);

  useEffect(() => {
    listing();
  }, [refresh, rowsPerPage, searchFilter, page, partnerFilterSelect]);
  const id = useParams();

  useEffect(() => {
    if (id?.id > 0) {
      setSelectedContest(id);
      setOpenWinnerList(true);
    }
  }, [id]);

  const listing = () => {
    setLoading(true);
    let data = {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      search: searchFilter,
    };

    if (partnerFilterSelect) {
      data.partnerId = partnerFilterSelect.value;
    }

    listApi(APIs.LIST_CONTESTS, { ...data })
      .then((res) => {
        setUsers(res?.data);
        setTotalCount(res?.count);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  /*Close Modal Handler */

  const closeModal = () => {
    setCloneModalVisible(!cloneModalVisible);
  };

  /*close Winner list Modal*/

  const closeWinnerList = () => {
    setSelectedContest('');
    setOpenWinnerList(!openWinnerList);
  };

  const closeContestStats = () => {
    setSelectedContest('');
    setContestStats(!openContestStats);
  };

  const closeSpecialQuizFlow = () => {
    setSelectedContest('');
    setOpenSpecialQuizFlow(!openSpecialQuizFlow);
  };

  const closeQuizModal = () => {
    setSelectedGame({});
    setOpenQuizModal(false);
  };

  //Styles for filter //
  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 56,
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2,
      };
    },
  };
  const handleEditPermission =
    user && user.roles && user.roles.some((role) => role?.title === 'Contest List' && role?.edit === true);

  const isSuperAdmin = user?.isSuperAdmin;

  /*Contet Filter Api  */
  const promisePartnerDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_PARTNERS, { search: inputValue, sendOnlyActive: true }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={item?.name} src={item?.profileImage} />
            <Typography variant="subtitle2" noWrap>
              {item?.name}
            </Typography>
          </Stack>
        ),
        value: item?.id,
      }));

      resolve(filter);
    });
  };

  const loadPartnerData = debounce(promisePartnerDataOptions, 300);

  const MenuConfirmClick = (row, key) => {
    if (key === MENU_CONSTANT.CLONE) {
      confirmAlert({
        title: <h6>Confirm to clone</h6>,
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              delete row?.id;
              delete row?.createdAt;
              delete row?.updatedAt;
              delete row?.locations?.map((loaction) => {
                delete loaction?.id;
                delete loaction?.contestId;
              });
              row.gameIds = [row?.gameIds?.[0]?.id];
              addEditApi(APIs.ADD_EDIT_CONTEST, row).then((result) => {
                navigate(`/contests/edit/${result?.data?.id}`);
              });
            },
          },
          {
            label: 'No',
          },
        ],
      });
    }
    if (key === MENU_CONSTANT.CONTEST_STATUS) {
      const data = {
        id: row.id,
        type: 3,
        active: !row.isActive,
      };
      confirmAlert({
        title: <h6>Confirm for Status Change</h6>,
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              addEditApi(APIs.ADD_EDIT_BLOCK, data).then((result) => {
                toast.success('Updated Successfully');
                listing();
              });
            },
          },
          {
            label: 'No',
          },
        ],
      });
    }
    if (key === MENU_CONSTANT.QUIZ_STATUS) {
      const data = {
        gameId: row?.gameIds?.[0]?.id,
      };
      confirmAlert({
        title: <h6>Confirm for Status Change</h6>,
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              addEditApi(APIs.ADD_EDIT_QUIZ, data).then((result) => {
                toast.success('Updated Successfully');
                listing();
              });
            },
          },
          {
            label: 'No',
          },
        ],
      });
    }
    if (key === MENU_CONSTANT.ASSIGN_REWARD) {
      const data = {
        contestId: row?.id,
        gameId: row?.gameIds?.[0]?.id,
      };

      confirmAlert({
        title: <h6>Confirm for Assign Rewards Auto</h6>,
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              addEditApi(APIs.AUTO_ASSIGN_REWARD, data).then((result) => {
                toast.success('Updated Successfully');
                listing();
              });
            },
          },
          {
            label: 'No',
          },
        ],
      });
    }
    if (key === MENU_CONSTANT.NOTIFICATIONS) {
      const data = {
        contestId: row?.id,
      };

      confirmAlert({
        title: <h6>Send Notifications to all App Users?</h6>,
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              addEditApi(APIs.SEND_CONTEST_NOTIFICATIONS, data).then((result) => {
                toast.success('Sent Successfully');
              });
            },
          },
          {
            label: 'No',
          },
        ],
      });
    }
  };

  //function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  };

  return (
    <Page title="Contests">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Contests
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to={`${ROUTES.CONTESTS}/${ROUTES.ADD}`}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Contest
          </Button>
        </Stack>

        <Card>
          <Grid container spacing={2} padding={1}>
            <Grid>
              <UserListToolbar numSelected={selected.length} filterName={search} onFilterName={handleSearch} />
            </Grid>

            <Grid className="filterInput" xs={12} md={3.5}>
              <AsyncSelect
                isClearable
                cacheOptions
                defaultOptions
                placeholder="Filter by partner"
                styles={customStyles}
                loadOptions={loadPartnerData}
                value={partnerFilterSelect}
                onChange={(data) => {
                  setPartnerFilterSelect(data);
                }}
              />
            </Grid>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {users.map((row, index) => {
                      const {
                        id,
                        contestType,
                        imageLink,
                        name,
                        isActive,
                        quizActive,
                        rewardActive,
                        description,
                        weekName,
                        price,
                        startDateTime,
                        isAgeNeeded,
                        minimumAge,
                        termsLink,
                        rulesLink,
                        createdAt,
                        specialQuiz,
                        scheduleActiveDate,
                        specialQuizType,
                        specialQuizData,
                        endDateTime,
                        postOnTwitter,
                        gameIds
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      const options = [
                        {
                          label: 'Edit',
                          icon: 'eva:edit-fill',
                          // onClick: () => navigate(`/contests/edit/${id}`),
                          onClick: () => openLinkNewTab(`/contests/edit/${id}`),
                        },
                        {
                          label: 'Clone Contest',
                          icon: 'eva:copy-outline',
                          onClick: () => MenuConfirmClick(row, MENU_CONSTANT.CLONE),
                        },
                        {
                          label: 'Send Contest Notifications',
                          icon: 'eva:bell-outline',
                          onClick: () => MenuConfirmClick(row, MENU_CONSTANT.NOTIFICATIONS),
                        },

                        {
                          label: isActive ? 'Inactive Contest' : 'Active Contest',
                          icon: 'eva:edit-fill',
                          onClick: () => MenuConfirmClick(row, MENU_CONSTANT.CONTEST_STATUS),
                        },

                        {
                          label: 'Clone Reward',
                          icon: 'eva:copy-outline',
                          onClick: () => {
                            setSelectedContest(row?.id);
                            setCloneModalVisible(!cloneModalVisible);
                          },
                        },
                        {
                          label: 'Contest Stats',
                          icon: 'raphael:piechart',
                          onClick: () => {
                            setSelectedContest(row);
                            setContestStats(!openContestStats);
                          },
                        },
                        {
                          label: 'View leaderBoard/Winners',
                          icon: 'eva:copy-outline',
                          onClick: () => {
                            setSelectedContest(row);
                            setOpenWinnerList(!openWinnerList);
                          },
                        },

                        {
                          label: 'Special Quiz',
                          icon: 'material-symbols:quiz-outline',
                          onClick: () => {
                            setSelectedGame({
                              id: id,
                              name,
                              specialQuizData,
                              specialQuiz,
                              specialQuizType,
                            });
                            setOpenQuizModal(true);
                          },
                        },
                        {
                          label: 'Create/Edit Quiz Flow',
                          icon: 'material-symbols:quiz-outline',
                          onClick: () => {
                            setSelectedContest(row);
                            setOpenSpecialQuizFlow(!openSpecialQuizFlow);
                          },
                        },
                        {
                          label: quizActive > 0 ? 'Inactive Quiz' : 'Active Quiz',
                          icon: 'eva:edit-fill',
                          onClick: () => MenuConfirmClick(row, MENU_CONSTANT.QUIZ_STATUS),
                        },
                        {
                          label: 'Assign rewards Auto',
                          icon: 'material-symbols:quiz-outline',
                          onClick: () => {
                            MenuConfirmClick(row, MENU_CONSTANT.ASSIGN_REWARD);
                          },
                        },
                      ];
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="right">
                            {(handleEditPermission || isSuperAdmin) && (
                              //  <MoreMenu options={quizActive > 0 ? options : options?.slice(0, -1)} />
                              <MoreMenu options={options} />
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar
                                alt={name}
                                src={imageLink}
                                onClick={() => showFullImage(imageLink)}
                                style={{ cursor: 'pointer' }}
                              />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {contestType === 1 ? 'Global' : contestType === 2 ? 'Location' : contestType === 3 ?  'Location + Global' : 'Link Based'}
                          </TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={isActive ? 'success' : 'error'}>
                              {isActive ? 'Active' : 'InActive'}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={quizActive > 0 ? 'success' : 'error'}>
                              {quizActive > 0 ? 'Active' : 'InActive'}
                            </Label>
                          </TableCell>
                          <TableCell
                            align="left"
                            onClick={() => {
                              navigate(`/reward-rules/${id}`);
                            }}
                          >
                            <Label variant="ghost" color={rewardActive > 0 ? 'success' : 'error'}>
                              {rewardActive > 0 ? 'Active' : 'InActive'}
                            </Label>
                          </TableCell>
                          <TableCell align="left">{description}</TableCell>
                          <TableCell align="left">
                            {startDateTime !== null ? moment(startDateTime).format(DATE_FORMAT_TIME) : '-'}
                          </TableCell>
                          <TableCell align="left">
                            {endDateTime !== null ? moment(endDateTime).format(DATE_FORMAT_TIME) : '-'}
                          </TableCell>
                          <TableCell align="left" sx={{minWidth: '200px'}}>
                            {gameIds.length > 0 ? `${gameIds[0]?.AwayTeam?.teamName} VS ${gameIds[0]?.HomeTeam?.teamName}` : '-'}
                          </TableCell>
                          <TableCell align="left">
                            {scheduleActiveDate !== null ? moment(scheduleActiveDate).format(DATE_FORMAT_TIME) : '-'}
                          </TableCell>
                          <TableCell align="left">{postOnTwitter.toString()}</TableCell>
                          <TableCell align="left">{weekName}</TableCell>
                          <TableCell align="left">{price}</TableCell>
                          <TableCell align="left">{isAgeNeeded.toString()}</TableCell>
                          <TableCell align="left">{minimumAge}</TableCell>
                          <TableCell align="left">{termsLink}</TableCell>
                          <TableCell align="left">{rulesLink}</TableCell>
                          <TableCell align="left">{moment(createdAt).format(DATE_FORMAT)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>
      <Dialog open={cloneModalVisible} fullWidth>
        <CloneRewardModal closeModal={closeModal} selectedContest={selectedContest} />
      </Dialog>
      <Dialog key="winnerList" open={openWinnerList} fullWidth>
        <WinnerList closeModal={closeWinnerList} selectedContestId={selectedContest} />
      </Dialog>
      <Dialog key="contestStats" open={openContestStats} fullWidth>
        <ContestStats closeModal={closeContestStats} selectedContestId={selectedContest} />
      </Dialog>
      <Dialog key="specialQuizFlow" open={openSpecialQuizFlow} fullWidth>
        <SpeicalQuizFlowModal
          closeModal={closeSpecialQuizFlow}
          selectedContestId={selectedContest}
          setRefresh={setRefresh}
        />
      </Dialog>
      <Dialog open={openQuizModal} fullWidth>
        <QuiModal closeModal={closeQuizModal} selectedGame={selectedGame} setRefresh={setRefresh} />
      </Dialog>
      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
    </Page>
  );
}
