import { Fragment, useEffect, useState } from 'react';
import Page from '../../components/Page';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { contestTypes, GoogleMapsApiKey, QUESTION_TO_SHOW_CONSTANT } from '../../contants/contants';
import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Box,
  Grid,
  Button,
  Switch,
  FormControlLabel,
  IconButton,
  colors,
} from '@mui/material';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import Iconify from '../../components/Iconify';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { addEditContestSchema, addEditContestLocationSchema } from 'src/utils/validationSchemas';
import { addEditApi, listApi } from 'src/apis/admin';
import { APIs } from 'src/apis';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'src/router/routes';
import { toast } from 'react-toastify';
import Autocomplete from 'react-google-autocomplete';
import React from 'react';
import BackButton from 'src/components/backButton';
import { DATE_FORMAT_TIME } from '../../contants/contants';
import UploadImage from 'src/components/uploadImage';
import { IMAGE_MAX_SIZE } from '../../contants/contants';

const endTime = (date) => {
  const currentDate = new Date(date);
  currentDate.setHours(currentDate.getHours() + 4);
  return new Date(currentDate);
};

const initialValues = {
  contestType: 1,
  name: '',
  description: '',
  weekName: '',
  radius: 0.3,
  price: 0,
  isAgeNeeded: false,
  minimumAge: 21,
  ageDescription: 'Intended for legal drinking age consumers only',
  gameIds: '',
  imageLink: '',
  contestBackgroundImage: '',
  overview: [''],
  howItWorks: [''],
  termsLink: '',
  rulesLink: '',
  startDateTime: null,
  endDateTime: null,
  scheduleActiveDate: null,
  locations: [''],
  partnerId: '',
  emailImage: '',
  emailSubject: '',
  emailBodyContent: '',
  sendNotifications: false,
  postOnTwitter: false,
  sendContestUserNotifications: false,
  challengeShareCard: '',
  beatPicksShareCard: '',
  twitterShareCard: '',
  gamesToPlay: [],
  contestEntryPoints: '',
  questionsToShow: null,
};

const placePickerStyles = {
  width: '100%',
  height: '52px',
  padding: '10px',
  borderRadius: '8px',
  border: '1px solid hsl(0, 0%, 80%)',
};

const labelStyle = {
  fontSize: '10px'
}

const imageType = {
  contestImage: 1,
  contestBackgroundImage: 2,
  emailImage: 3,
  challengeShareCard: 4,
  beatPicksShareCard: 5,
  twitterShareCard: 6,
};

export default function AddEditContest({ isEdit }) {
  const navigate = useNavigate();
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [contestData, setContestData] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [contestType, setContestType] = useState(1);
  const [removeLocations, setRemoveLocations] = useState([]);

  const [image, setImage] = useState();
  const [wallpaperImage, setWallpaperImage] = useState();
  const [emailImage, setEmailImage] = useState();
  const [challengeShareCard, setChallengeShareCard] = useState();
  const [beatPicksShareCard, setBeatPicksShareCard] = useState();
  const [twitterShareCard, setTwitterShareCard] = useState();

  const [sizeError1, setSizeError1] = useState(false);
  const [sizeError2, setSizeError2] = useState(false);
  const [sizeError3, setSizeError3] = useState(false);
  const [sizeError4, setSizeError4] = useState(false);
  const [sizeError5, setSizeError5] = useState(false);
  const [sizeError6, setSizeError6] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      listApi(APIs.LIST_CONTESTS, { id })
        .then((result) => {
          if (result?.data?.length && `${result?.data[0]?.id}` === id) {
            const data = cloneDeep(result?.data[0]);
            const partnerId = {
              label: data?.partnerData?.name,
              value: data?.partnerData?.id,
            };
            data.partnerId = partnerId;
            if (data?.locations?.length) {
              data.radius = data?.locations?.[0]?.radius;
            }

            const gameIds = data?.gameIds?.map((item) => ({
              label: `${item?.AwayTeam?.teamName} VS ${item?.HomeTeam?.teamName}  |  ${moment(item?.startTime).format(
                DATE_FORMAT_TIME
              )}`,
              value: item,
            }));

            if (data?.gamesToPlay === null || data?.gamesToPlay?.length === 0 || data?.gamesToPlay === undefined) {
              data.gamesToPlay = [];
            } else {
              data.gamesToPlay = data?.gamesToPlay?.map((item) => ({
                label: `${item?.AwayTeam?.teamName} VS ${item?.HomeTeam?.teamName}  |  ${moment(item?.startTime).format(
                  DATE_FORMAT_TIME
                )}`,
                value: item,
              }));
            }

            data.gameIds = gameIds?.[0];
            data.startDateTime = new Date(data?.startDateTime);
            if (data?.endDateTime === null || data?.endDateTime === undefined || data?.endDateTime === '') {
              data.endDateTime = endTime(data?.startDateTime);
            }
            else data.endDateTime = new Date(data?.endDateTime);

            if (!data?.scheduleActiveDate || data?.scheduleActiveDate === '') {
              data.scheduleActiveDate = null;
            }
            else data.scheduleActiveDate = new Date(data?.scheduleActiveDate);

            setContestData(data);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  const handleFormSubmit = (data) => {
  
    if (data?.contestType !== 1) {
      const locations = data?.locations?.map((item, index) => {
        item.radius = parseFloat(data?.radius);
        if (item?.id) {
          return item;
        } else {
          return {
            address: item?.formatted_address,
            placeId: item?.place_id,
            lat: item?.geometry?.location?.lat(),
            lng: item?.geometry?.location?.lng(),
            id: contestData?.locations?.[index]?.id || '',
            radius: parseFloat(data?.radius),
          };
        }
      });

      data.locations = locations;
    } else {
      delete data.locations;
    }

    if (image) {
      toast.error('Please upload image first');
    } else if (locationError === true) {
    } 
    else {
      toast.success('Contest updated successfully')
      data.partnerId = data.partnerId.value;

      if (data?.gameIds && data?.gameIds?.value) data.gameIds = [data?.gameIds?.value?.id];

      if (data?.gamesToPlay?.length > 0) {
        data.gamesToPlay = data?.gamesToPlay?.map((item) => item?.value?.id);
      }

      addEditApi(APIs.ADD_EDIT_CONTEST, { ...data }).then(() => {
        
      }).finally(() => {
        // setContestId(data.id)
        window.location.reload(true)
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: contestData || initialValues,
    validationSchema: contestType === 1 ? addEditContestSchema : addEditContestLocationSchema,
    onSubmit: handleFormSubmit,
  });
  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;
  
  useEffect(() => {
    setContestType(values?.contestType);
  }, [values?.contestType]);

  const promiseOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_GAMES, { search: inputValue, fromAdminListing: true, fromAdmin: true, skip : 0, limit : 30}).then((result) => {
      const filter = result?.games.map((item) => ({
        label: `${item?.AwayTeam?.teamName} VS ${item?.HomeTeam?.teamName}  |  ${moment(item?.startTime).format(
          DATE_FORMAT_TIME
        )}`,
        value: item,
      }));
      resolve(filter);
    });
  };

  const partnerOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_PARTNERS, { search: inputValue }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      resolve(filter);
    });
  };

  const loadGames = debounce(promiseOptions, 300);
  const gamesToPlayOption = debounce(promiseOptions, 300);
  const loadPartners = debounce(partnerOptions, 300);

  const removeLocationHandler = (id) => {
    removeLocations.push(id);
    setRemoveLocations((removeLocations) => [...removeLocations]);
    setFieldValue('removeLocations', removeLocations);
  };

  const handleImageChange = (e) => {
    setSizeError1(false);
    if (e?.target?.files?.[0]?.size > IMAGE_MAX_SIZE) {
      setSizeError1(true);
    } else {
      setImage(e?.target?.files?.[0]);
    }
  };

  const handleWallpaperImageChange = (e) => {
    setSizeError2(false);
    if (e?.target?.files?.[0]?.size > IMAGE_MAX_SIZE) {
      setSizeError2(true);
    } else {
      setWallpaperImage(e?.target?.files?.[0]);
    }
  };

  const handleEmailImageChange = (e) => {
    setSizeError3(false);
    if (e?.target?.files?.[0]?.size > IMAGE_MAX_SIZE) {
      setSizeError3(true);
    } else {
      setEmailImage(e?.target?.files?.[0]);
    }
  };
  const handleChallengeShareCardChange = (e) => {
    setSizeError4(false);
    if (e?.target?.files?.[0]?.size > IMAGE_MAX_SIZE) {
      setSizeError4(true);
    } else {
      setChallengeShareCard(e?.target?.files?.[0]);
    }
  };

  const handleBeatPicksShareCardChange = (e) => {
    setSizeError5(false);
    if (e?.target?.files?.[0]?.size > IMAGE_MAX_SIZE) {
      setSizeError5(true);
    } else {
      setBeatPicksShareCard(e?.target?.files?.[0]);
    }
  };
  const handleTwitterShareCardChange = (e) => {
    setSizeError6(false);
    if (e?.target?.files?.[0]?.size > IMAGE_MAX_SIZE) {
      setSizeError6(true);
    } else {
      setTwitterShareCard(e?.target?.files?.[0]);
    }
  };

  const handleAllImages = (type) => {
    setImageLoading(true);
    const data = new FormData();
    let key;
    switch (type) {
      case 1:
        data.append('file', image);
        key = 'imageLink';
        setImage(null);
        break;
      case 2:
        data.append('file', wallpaperImage);
        key = 'contestBackgroundImage';
        setWallpaperImage(null);
        break;
      case 3:
        data.append('file', emailImage);
        key = 'emailImage';
        setEmailImage(null);
        break;
      case 4:
        data.append('file', challengeShareCard);
        key = 'challengeShareCard';
        setChallengeShareCard(null);
        break;
      case 5:
        data.append('file', beatPicksShareCard);
        key = 'beatPicksShareCard';
        setBeatPicksShareCard(null);
        break;
      case 6:
        data.append('file', twitterShareCard);
        key = 'twitterShareCard';
        setTwitterShareCard(null);
        break;
      default:
        break;
    }

    if (!data?.get('file')) {
      setImageLoading(false);
      return;
    }
    addEditApi(APIs.UPLOAD_IMAGE, data)
      .then((result) => {
        setFieldValue(key, result?.profileImg);
      })
      .finally(() => setImageLoading(false));
  };

  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 55,
        border: '',
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2,
      };
    },
  };
  function toggleSwitch1() {
    values.sendNotifications = !values.sendNotifications;
    if (values.sendNotifications) {
      values.sendContestUserNotifications = false; // If switch1 is turned on, switch2 is turned off
    }
  }
  function toggleSwitch2() {
    values.sendContestUserNotifications = !values.sendContestUserNotifications;
    if (values.sendContestUserNotifications) {
      values.sendNotifications = false; // If switch2 is turned on, switch1 is turned off
    }
  }

  useEffect(() => {
    const placeIdArray = values?.locations?.map((elem) => elem.place_id);
    const pureArray = placeIdArray.filter((items) => items != null);
    const hasDuplicates = (arr) => arr.length !== new Set(arr).size;
    const checkDuplicate = hasDuplicates(pureArray);
    if (checkDuplicate) {
      setLocationError(true);
    } else {
      setLocationError(false);
    }
  }, [values?.locations]);

  return (
    <Page title="Contest" loading={loading}>
      <Container>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                {isEdit ? 'Edit Contest' : 'Add Contest'}
              </Typography>
              <div>
                <BackButton onClick={() => navigate(ROUTES.CONTESTS)} />

                <Button 
                // disabled={isSubmitting}
                variant="contained" 
                type="submit" 
                startIcon={<Iconify icon="eva:save-outline" />}>
                Save Contest
                </Button>
              </div>
            </Stack>

            <Card>
              <Box sx={{ flexGrow: 1, padding: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <label style={labelStyle}>Contest Type</label>
                    <Select
                      options={contestTypes}
                      placeholder="Select ContestType"
                      styles={customStyles}
                      value={contestTypes.filter((item) => item?.value === values?.contestType)}
                      defaultValue={values?.contestType}
                      onChange={(data) => {
                        setFieldValue('contestType', data?.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <label style={labelStyle}>Select Partner</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      placeholder="Select Partner"
                      styles={customStyles}
                      loadOptions={loadPartners}
                      value={values?.partnerId}
                      onChange={(data) => {
                        setFieldValue('partnerId', data);
                      }}
                    />
                    {Boolean(touched.partnerId && errors.partnerId) && (
                      <Typography variant="caption" display="block" gutterBottom color={'red'}>
                        {errors.partnerId}
                      </Typography>
                    )}
                  </Grid>
                  {(values?.contestType === 2 || values?.contestType === 3) && (
                    <>
                      <Grid item xs={12} md={3} mt={3}>
                        <TextField
                          fullWidth
                          label="Visible Radius (In Miles)"
                          {...getFieldProps('radius')}
                          error={Boolean(touched.radius && errors.radius)}
                          helperText={touched.radius && errors.radius}
                        />
                      </Grid>
                    </>
                  )}
                  {(values?.contestType === 2 || values?.contestType === 3) && (
                    <>
                      <Grid container paddingLeft={2} paddingRight={2} paddingTop={2}>
                        <FieldArray
                          name="locations"
                          render={(arrayHelpers) => (
                            <>
                              {(values?.locations || [])?.map((item, index) => (
                                <Fragment key={item?.id}>
                                  <Grid key={item?.place_id} item xs={8} md={10} paddingBottom={1}>
                                    <Autocomplete
                                      key={item?.place_id}
                                      apiKey={GoogleMapsApiKey}
                                      onPlaceSelected={(place) => {
                                        setFieldValue(`locations.${index}`, place);
                                      }}
                                      defaultValue={
                                        values?.locations?.[index]?.formatted_address ||
                                        values?.locations?.[index]?.address
                                      }
                                      style={placePickerStyles}
                                      options={{ fields: ['ALL'], types: ['establishment', 'geocode'] }}
                                    />
                                    {!locationError &&
                                      Boolean(touched?.locations?.[index] && errors?.locations?.[index]) && (
                                        <Typography
                                          variant="caption"
                                          display="block"
                                          gutterBottom
                                          color={'red'}
                                          paddingTop={1}
                                          paddingLeft={2}
                                        >
                                          {errors?.locations?.[index]}
                                        </Typography>
                                      )}
                                  </Grid>

                                  {values?.locations?.length !== 1 && (
                                    <IconButton
                                      size="large"
                                      sx={{ ml: 2, color: colors.red[500] }}
                                      onClick={() => {
                                        removeLocationHandler(values?.locations?.[index]?.id);
                                        arrayHelpers.remove(index);
                                      }}
                                    >
                                      <Iconify icon="eva:minus-square-fill" />
                                    </IconButton>
                                  )}
                                  <Grid item xs={1} md={1}>
                                    <IconButton
                                      size="large"
                                      sx={{ ml: 2, color: 'primary.main' }}
                                      onClick={() => {
                                        arrayHelpers.push('');
                                      }}
                                    >
                                      <Iconify icon="eva:plus-square-fill" />
                                    </IconButton>
                                  </Grid>
                                </Fragment>
                              ))}
                            </>
                          )}
                        />
                      </Grid>
                      <Grid container paddingLeft={2} paddingRight={2}>
                        {locationError ? (
                          <div className="errorText">Please remove duplicate locations</div>
                        ) : (
                          <div className="trasparentText">Trasparent Text</div>
                        )}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Contest Name"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Description"
                      {...getFieldProps('description')}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="Week Name"
                      {...getFieldProps('weekName')}
                      error={Boolean(touched.weekName && errors.weekName)}
                      helperText={touched.weekName && errors.weekName}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="Price"
                      {...getFieldProps('price')}
                      error={Boolean(touched.price && errors.price)}
                      helperText={touched.price && errors.price}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Select
                      isClearable
                      options={QUESTION_TO_SHOW_CONSTANT}
                      placeholder="Select Question To Show"
                      styles={customStyles}
                      value={QUESTION_TO_SHOW_CONSTANT.filter((item) => item?.value === values?.questionsToShow)}
                      defaultValue={values?.questionsToShow}
                      onChange={(data) => {
                        if (data === null) {
                          setFieldValue('questionsToShow', null);
                        } else {
                          setFieldValue('questionsToShow', data?.value);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <FormControlLabel
                      control={<Switch />}
                      label="Is Age needed?"
                      checked={values?.isAgeNeeded}
                      onChange={(e) => setFieldValue('isAgeNeeded', e?.target?.checked)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      fullWidth
                      label="Minimum Age"
                      {...getFieldProps('minimumAge')}
                      error={Boolean(touched.minimumAge && errors.minimumAge)}
                      helperText={touched.minimumAge && errors.minimumAge}
                      disabled={!values?.isAgeNeeded}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      fullWidth
                      label="Age Description"
                      {...getFieldProps('ageDescription')}
                      helperText={touched.ageDescription && errors.ageDescription}
                      disabled={!values?.isAgeNeeded}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      type="number"
                      fullWidth
                      label="Entry Points"
                      {...getFieldProps('contestEntryPoints')}
                      helperText={touched.contestEntryPoints && errors.contestEntryPoints}
                    />
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                  <label style={labelStyle}>Select Main Game</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      // isMulti
                      placeholder="Select Main Game"
                      styles={customStyles}
                      loadOptions={loadGames}
                      value={values?.gameIds}
                      onChange={(data) => {
                        setFieldValue('gameIds', data || []);
                        setFieldValue('startDateTime', new Date(data?.value?.startTime) || '');
                        setFieldValue('endDateTime', endTime(data?.value?.startTime));
                      }}
                    />
                    {Boolean(touched.gameIds && errors.gameIds) && (
                      <Typography variant="caption" display="block" gutterBottom color={'red'}>
                        {errors.gameIds}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>   
                  <label style={labelStyle}>Sub Games</label>                
                    <AsyncSelect
                      cacheOptions
                      // defaultOptions
                      isMulti
                      placeholder="Search/Select Sub Games with Name"
                      styles={customStyles}
                      loadOptions={gamesToPlayOption}
                      value={values?.gamesToPlay}
                      onChange={(data) => {
                        setFieldValue('gamesToPlay', data || []);
                      }}
                    />
                    {Boolean(touched.gamesToPlay && errors.gamesToPlay) && (
                      <Typography variant="caption" display="block" gutterBottom color={'red'}>
                        {errors.gamesToPlay}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        value={values?.startDateTime}
                        label="Start date"
                        onChange={(date) => setFieldValue('startDateTime', new Date(date))}
                        // disablePast={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={Boolean(touched.startDateTime && errors.startDateTime)}
                            helperText={touched.startDateTime && errors.startDateTime}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        value={values?.endDateTime}
                        label="End date"
                        onChange={(date) => setFieldValue('endDateTime', date)}
                        // disablePast={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={Boolean(touched.endDateTime && errors.endDateTime)}
                            helperText={touched.endDateTime && errors.endDateTime}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        value={values?.scheduleActiveDate}
                        label="Schedule active date"
                        onChange={(date) => setFieldValue('scheduleActiveDate', date)}
                        // disablePast={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={Boolean(touched.scheduleActiveDate && errors.scheduleActiveDate)}
                            helperText={touched.scheduleActiveDate && errors.scheduleActiveDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid container padding={2}>
                    <FieldArray
                      name="overview"
                      render={(arrayHelpers) => (
                        <>
                          {values.overview.map((item, index) => (
                            <Fragment key={index}>
                              <Grid item xs={8} md={10}>
                                <TextField
                                  fullWidth
                                  name={`overview.${index}`}
                                  label={index === 0 ? 'Overview' : ''}
                                  placeholder={index === 0 ? '1. Overview' : `${index + 1}. Overview`}
                                  {...getFieldProps(`overview.${index}`)}
                                  error={Boolean(touched.overview?.[index] && errors.overview?.[index])}
                                  helperText={touched.overview?.[index] && errors.overview?.[index]}
                                />
                              </Grid>
                              {values?.overview?.length !== 1 && (
                                <IconButton
                                  size="large"
                                  sx={{ ml: 2, color: colors.red[500] }}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <Iconify icon="eva:minus-square-fill" />
                                </IconButton>
                              )}
                              <Grid item xs={1} md={1}>
                                <IconButton
                                  size="large"
                                  sx={{ ml: 2, color: 'primary.main' }}
                                  onClick={() => arrayHelpers.insert(index + 1, '')}
                                >
                                  <Iconify icon="eva:plus-square-fill" />
                                </IconButton>
                              </Grid>
                            </Fragment>
                          ))}
                        </>
                      )}
                    />
                  </Grid>

                  <Grid container padding={2}>
                    <FieldArray
                      name="howItWorks"
                      render={(arrayHelpers) => (
                        <>
                          {values.howItWorks.map((item, index) => (
                            <Fragment key={index}>
                              <Grid item xs={8} md={10}>
                                <TextField
                                  fullWidth
                                  name={`howItWorks.${index}`}
                                  label={index === 0 ? 'How it Works' : ''}
                                  placeholder={index === 0 ? '1. How it Works' : `${index + 1}. How it Works`}
                                  {...getFieldProps(`howItWorks.${index}`)}
                                  error={Boolean(touched.howItWorks?.[index] && errors.howItWorks?.[index])}
                                  helperText={touched.howItWorks?.[index] && errors.howItWorks?.[index]}
                                />
                              </Grid>
                              {values?.howItWorks?.length !== 1 && (
                                <IconButton
                                  size="large"
                                  sx={{ ml: 2, color: colors.red[500] }}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <Iconify icon="eva:minus-square-fill" />
                                </IconButton>
                              )}
                              <Grid item xs={1} md={1}>
                                <IconButton
                                  size="large"
                                  sx={{ ml: 2, color: 'primary.main' }}
                                  onClick={() => arrayHelpers.insert(index + 1, '')}
                                >
                                  <Iconify icon="eva:plus-square-fill" />
                                </IconButton>
                              </Grid>
                            </Fragment>
                          ))}
                        </>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Terms Link"
                      {...getFieldProps('termsLink')}
                      error={Boolean(touched.termsLink && errors.termsLink)}
                      helperText={touched.termsLink && errors.termsLink}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Rules Link"
                      {...getFieldProps('rulesLink')}
                      error={Boolean(touched.rulesLink && errors.rulesLink)}
                      helperText={touched.rulesLink && errors.rulesLink}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField fullWidth label="Email Subject" {...getFieldProps('emailSubject')} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField fullWidth label="Email Body Content" {...getFieldProps('emailBodyContent')} />
                  </Grid>
                  <Grid item xs={12} md={12} style={{ display: 'flex', marginTop: '20px', marginBottom: '20px' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values?.sendNotifications}
                          onChange={(date) => {
                            toggleSwitch1();
                            setFieldValue('sendNotifications', date?.target?.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="Send Notifications [All]"
                    />
                    <FormControlLabel
                      style={{ marginLeft: '40px' }}
                      control={
                        <Switch
                          checked={values?.sendContestUserNotifications}
                          onChange={(date) => {
                            toggleSwitch2();
                            setFieldValue('sendContestUserNotifications', date?.target?.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="Send Notifications [Partner]"
                    />
                    <FormControlLabel
                      style={{ marginLeft: '40px' }}
                      control={
                        <Switch
                          checked={values?.postOnTwitter}
                          onChange={(date) => {
                            setFieldValue('postOnTwitter', date?.target?.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="Post On Twitter"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <UploadImage
                      title="Contest Image"
                      fieldName="imageLink"
                      stateImage={image}
                      image={image ? URL.createObjectURL(image) : values?.imageLink}
                      fileSizeExceeded={sizeError1}
                      onChange={handleImageChange}
                      uploadImage={() => handleAllImages(imageType.contestImage)}
                      removeImage={() => setImage(null)}
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <UploadImage
                      title="Wallpaper Image"
                      fieldName="contestBackgroundImage"
                      stateImage={wallpaperImage}
                      image={wallpaperImage ? URL.createObjectURL(wallpaperImage) : values?.contestBackgroundImage}
                      fileSizeExceeded={sizeError2}
                      onChange={handleWallpaperImageChange}
                      uploadImage={() => handleAllImages(imageType.contestBackgroundImage)}
                      removeImage={() => setWallpaperImage(null)}
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <UploadImage
                      title="Email Image"
                      fieldName="emailImage"
                      stateImage={emailImage}
                      image={emailImage ? URL.createObjectURL(emailImage) : values?.emailImage}
                      fileSizeExceeded={sizeError3}
                      onChange={handleEmailImageChange}
                      uploadImage={() => handleAllImages(imageType.emailImage)}
                      removeImage={() => setEmailImage(null)}
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <UploadImage
                      title="Share Card (Odds)"
                      fieldName="challengeShareCard"
                      stateImage={challengeShareCard}
                      image={challengeShareCard ? URL.createObjectURL(challengeShareCard) : values?.challengeShareCard}
                      fileSizeExceeded={sizeError4}
                      onChange={handleChallengeShareCardChange}
                      uploadImage={() => handleAllImages(imageType.challengeShareCard)}
                      removeImage={() => setChallengeShareCard(null)}
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <UploadImage
                      title="Share Card (No-Odds)"
                      fieldName="beatPicksShareCard"
                      stateImage={beatPicksShareCard}
                      image={beatPicksShareCard ? URL.createObjectURL(beatPicksShareCard) : values?.beatPicksShareCard}
                      fileSizeExceeded={sizeError5}
                      onChange={handleBeatPicksShareCardChange}
                      uploadImage={() => handleAllImages(imageType.beatPicksShareCard)}
                      removeImage={() => setChallengeShareCard(null)}
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <UploadImage
                      title="Twitter Card"
                      fieldName="twitterShareCard"
                      stateImage={twitterShareCard}
                      image={twitterShareCard ? URL.createObjectURL(twitterShareCard) : values?.twitterShareCard}
                      fileSizeExceeded={sizeError6}
                      onChange={handleTwitterShareCardChange}
                      uploadImage={() => handleAllImages(imageType.twitterShareCard)}
                      removeImage={() => setTwitterShareCard(null)}
                      touched={touched}
                      errors={errors}
                    />
                    {values?.twitterShareCard && (
                      <IconButton
                        size="large"
                        sx={{ ml: 2, color: colors.red[500] }}
                        onClick={() => {
                          setFieldValue('twitterShareCard', null);
                          setTwitterShareCard(null);
                        }}
                      >
                        <Iconify icon="eva:minus-square-fill" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
