import { Navigate, Route, Routes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import { ROUTES } from './routes';
import {
  LoginPage,
  Page404,
  UsersPage,
  AdminsPage,
  ContestsPage,
  AddEditContestPage,
  UserWallet,
  GamesPage,
  ReportedUsers,
  NFTPage,
  NFTNewsLetterPage,
  RewardTypes,
  AddEditRewardPage,
  RewardRulesPage,
  AddEditRewardRulesPage,
  ContestFormsPage,
  ContestWallpaperPage,
  AddEditContestWallpapers,
  WallpaperPurchase,
  PartnerPage,
  PartnersRewards,
  Generator,
  AddEditQuestionBank,
  QuestionBankList,
  FAQs,
} from '../pages';
import Products from '../pages/Products';
import DashboardApp from '../pages/DashboardApp';
import TeamsPage from 'src/pages/games/teamsList';
import PartnerUsers from 'src/pages/partners/partnerUsers';
import PartnerContests from 'src/pages/partners/partnerContests';
import SeriesList from 'src/pages/series/seriesList';
import AddEditSeries from 'src/pages/series/seriesList/addEditSeries';
import SupportRequest from 'src/pages/content/supportRequest';
import PartnerLogin from 'src/pages/partners/partnerLogin';
import PartnerDashboard from 'src/pages/partners/partnerDashboard';
import PartnerLayout from 'src/layouts/partner';
import ProtectedRoute from './protectedRoute';
import ContestsInstructions from 'src/pages/contests/contestsInstructions';
import QuestionStats from 'src/pages/contests/questionStats';
import SchedulerNotifications from 'src/pages/scheduler/notifications';
import AddEditSchedulerNotifications from 'src/pages/scheduler/notifications/addEdit';

export default function Router() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path={ROUTES.DASHBOARD} element={<DashboardApp />} />
        <Route path={ROUTES.USERS} element={<UsersPage />} />
        <Route path={ROUTES.USERS_WALLET} element={<UserWallet />} />
        <Route path={ROUTES.REPORTED_USERS} element={<ReportedUsers />} />
        <Route path={ROUTES.ADMINS} element={<AdminsPage />} />
        <Route path={ROUTES.CONTESTS}>
          <Route index element={<ContestsPage />} />
          <Route path={ROUTES?.CONTESTSID} element={<ContestsPage />} />
          <Route path={ROUTES.ADD} element={<AddEditContestPage />} />
          <Route
            path={ROUTES.EDIT}
            element={
              <ProtectedRoute roles={['Contest List']} permissions={['edit']}>
                <AddEditContestPage isEdit />
              </ProtectedRoute>
            }
          />
          {/* <Route path={ROUTES.EDIT} element={<AddEditContestPage isEdit />} /> */}
        </Route>
        <Route path={ROUTES.CONTESTS_FORMS}>
          <Route index element={<ContestFormsPage />} />
        </Route> 
        <Route path={ROUTES.QUESTION_STATS}>
          <Route index element={<QuestionStats />} />
        </Route>
        <Route path={ROUTES.CONTESTS_WALLPAPERS}>
          <Route index element={<ContestWallpaperPage />} />
          <Route path={ROUTES.ADD} element={<AddEditContestWallpapers />} />
          <Route
            path={ROUTES.EDIT}
            element={
              <ProtectedRoute roles={['Contest Wallpapers']} permissions={['edit']}>
                <AddEditContestWallpapers isEdit />
              </ProtectedRoute>
            }
          />
          {/* <Route path={ROUTES.EDIT} element={<AddEditContestWallpapers isEdit />} /> */}
        </Route>
        <Route path={ROUTES.WALLPAPER_PURCHASE} element={<WallpaperPurchase />} />
        <Route path={ROUTES.CONTESTS_INSTRUCTIONS} element={<ContestsInstructions />} />

        <Route path={ROUTES.SCHEDULER_NOTIFICATIONS} element={<SchedulerNotifications />} />

        <Route path={ROUTES.SCHEDULER_NOTIFICATIONS_ADD} element={<AddEditSchedulerNotifications />} />
        <Route path={ROUTES.SCHEDULER_NOTIFICATIONS_ID} isEdit element={<AddEditSchedulerNotifications />} />

        <Route path={ROUTES.PARTNER} element={<PartnerPage />} />
        <Route path={ROUTES.PARTNERS_REWARDS} element={<PartnersRewards />} />
        <Route path={ROUTES.PARTNERS_STATS} element={<PartnerDashboard />} />
        <Route path={ROUTES.OPEN_AI_GENERATOR} element={<Generator />} />
        <Route path={ROUTES.QUESTION_BANK} element={<QuestionBankList />} />
        <Route
          path={ROUTES.ADD_EDIT_QUESTION}
          element={
            <ProtectedRoute roles={['Question Bank']} permissions={['edit']}>
              <AddEditQuestionBank />
            </ProtectedRoute>
          }
        />

        <Route path={ROUTES.SERIES}>
          <Route index element={<SeriesList />} />
          <Route path={ROUTES.SERIESADD} element={<AddEditSeries />} />
          <Route
            path={ROUTES.SERIESEDIT}
            element={
              <ProtectedRoute roles={['Series List']} permissions={['edit']}>
                <AddEditSeries isEdit />
              </ProtectedRoute>
            }
          />
          {/* <Route path={ROUTES.SERIESEDIT} element={<AddEditSeries isEdit />} /> */}
        </Route>
        <Route path={ROUTES.REWARDTYPES}>
          <Route index element={<RewardTypes />} />
          <Route path={ROUTES.REWARDADD} element={<AddEditRewardPage />} />
          <Route path={ROUTES.REWARDEDIT} element={<AddEditRewardPage isEdit />} />
        </Route>
        <Route path={ROUTES.REWARD_POSITION}>
          <Route index element={<RewardRulesPage />} />
          <Route path={ROUTES.REWARD_RULE_ID} element={<RewardRulesPage />} />
          <Route path={ROUTES.REWARD_RULE_ADD} element={<AddEditRewardRulesPage />} />
          <Route path={ROUTES.REWARD_RULE_EDIT} element={<AddEditRewardRulesPage isEdit />} />
        </Route>
        <Route path={ROUTES.GAMES} element={<GamesPage />} />
        <Route path={ROUTES.TEAMS} element={<TeamsPage />} />
        <Route path={ROUTES.PRODUCTS} element={<Products />} />
        <Route path={ROUTES.NFTPURCHASE} element={<NFTPage />} />
        <Route path={ROUTES.NFTNEWSLETTER} element={<NFTNewsLetterPage />} />
        <Route path={ROUTES.SUPPORT_REQUEST} element={<SupportRequest />} />
        <Route path={ROUTES.FAQ}>
          <Route index element={<FAQs />} />
        </Route>
      </Route>

      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Navigate to={ROUTES.DASHBOARD} />} />
        <Route path={ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={ROUTES.PARTNER_LOGIN} element={<PartnerLogin />} />

        <Route path="*" element={<Navigate to={ROUTES.NOT_FOUND} />} />
      </Route>
    
      <Route element={<PartnerLayout />}>
        <Route path={ROUTES.PARTNER_DASHBOARD} element={<PartnerDashboard />} />
        <Route path={ROUTES.PARTNER_USERS} element={<PartnerUsers />} />
        <Route path={ROUTES.PARTNER_CONTESTS} element={<PartnerContests />} />
      </Route>
    
      <Route path={ROUTES.NOT_FOUND} element={<Page404 />} />
      <Route path="*" element={<Navigate to={ROUTES.NOT_FOUND} replace />} />
    </Routes>
  );
}
