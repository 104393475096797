import * as Yup from 'yup';

export const LoginSchema = Yup.object().shape({
  emailId: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
});
export const emailValidationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
});

export const otpValidationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  otp: Yup.number().typeError('OTP must be a number').required('Email code is required'),
});
export const addEditAdminSchema = Yup.object().shape({
  emailId: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().test('Password', 'Password is required', (value, ctx) => {
    if (ctx?.parent?.id && !value) return true;
    return value ? true : false;
  }),
  name: Yup.string().required('Name is required'),
});

export const addEditFAQsSchema = Yup.object().shape({
  question: Yup.string().required('Question is required'),
  answer: Yup.string().required('Answer is required'),
});

export const addEditPartnerSchema = Yup.object().shape({
  emailId: Yup.string().email('Email must be a valid email address').required('Email is required'),
  // password: Yup.string().required('Password is required'),
  name: Yup.string().required('Name is required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
  address: Yup.string().required('Address is required'),
  // contestEntryPoints: Yup.number().required('Contest Entry Points is required'),
  contactPersonName: Yup.string().required('Name is required'),
  contactPersonEmail: Yup.string().email('Email must be a valid email address').required('Email is required'),
  // links: Yup.array().of(Yup.mixed().required('Required')),
});
export const addEditPartnerRewardSchema = Yup.object().shape({
  partnerId: Yup.object().required('Partner is required'),
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  points: Yup.number().required('Points is required'),
});
export const assignPartnerPointsSchema = Yup.object().shape({
  partnerId: Yup.object().required('Partner is required'),
  contestId: Yup.object().required('Contest is required'),
  points: Yup.number().required('Points is required'),
});
export const generateDeepLinkSchema = Yup.object().shape({
  alias: Yup.string().required('Alias is required'),
});
export const addEditContestSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  weekName: Yup.string().required('Required'),
  price: Yup.number().typeError('Must be number').required('Required'),
  isAgeNeeded: Yup.string().required('Required'),
  minimumAge: Yup.string().required('Required'),
  gameIds: Yup.mixed().required('Required'),
  // gamesToPlay: Yup.array().min(1, 'Required').required('Required'),
  imageLink: Yup.string().required('Image is Required'),
  overview: Yup.array().of(Yup.string().required('Required')),
  howItWorks: Yup.array().of(Yup.string().required('Required')),
  termsLink: Yup.string().required('Required'),
  rulesLink: Yup.string().required('Required'),
  startDateTime: Yup.string().required('Required'),
  contestType: Yup.number().required('Required'),
  partnerId: Yup.mixed().required('Required'),
});
export const addEditContestLocationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  weekName: Yup.string().required('Required'),
  radius: Yup.number().typeError('Must be number').required('Required'),
  price: Yup.number().typeError('Must be number').required('Required'),
  isAgeNeeded: Yup.string().required('Required'),
  minimumAge: Yup.string().required('Required'),
  // gameIds: Yup.array().min(1, 'Required').required('Required'),
  gameIds: Yup.mixed().required('Required'),
  // gamesToPlay: Yup.array().min(1, 'Required').required('Required'),
  imageLink: Yup.string().required('Image is Required'),
  overview: Yup.array().of(Yup.string().required('Required')),
  howItWorks: Yup.array().of(Yup.string().required('Required')),
  termsLink: Yup.string().required('Required'),
  rulesLink: Yup.string().required('Required'),
  startDateTime: Yup.string().required('Required'),
  contestType: Yup.number().required('Required'),
  locations: Yup.array().of(Yup.mixed().required('Required')),
  partnerId: Yup.mixed().required('Required'),
});

export const generateQuestionSchema = Yup.object().shape({
  count: Yup.number().typeError('Must be number').required('Required'),
  topic: Yup.string().required('Required'),
  subtopic: Yup.string().required('Required'),
  difficulty: Yup.object(),
  fetchFrom: Yup.object(),
});
export const generatorlisitngSchema = Yup.object().shape({
  question: Yup.array().of(
    Yup.object().shape({
      question: Yup.string().required('Required'),
      choices: Yup.array().of(Yup.object().shape({ option: Yup.string().required('Choice cannot be empty') })),
      explanation: Yup.string().required('Required'),
    })
  ),
  category: Yup.string().required('Required'),
  brand: Yup.string().required('Required'),
  timer: Yup.number().required('Required'),
  timerPoints: Yup.number().required('Required'),
  points: Yup.number().required('Required'),
});

export const addEditContestWallpaperSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  shortDescription: Yup.string().required('Required'),
  wallpapers: Yup.array().of(
    Yup.object().shape({
      mainWallpaper: Yup.string().required('Image is Required'),
      previewWallpaper: Yup.string().required('Image is Required'),
      price: Yup.number().typeError('Must be number').required('Required'),
    })
  ),
  contestId: Yup.mixed().required('Required'),
});

export const partnerWallpaperSchema = Yup.object().shape({
  wallpapers: Yup.array().of(
    Yup.object().shape({
      mainWallpaper: Yup.string().required('Image is Required'),
      previewWallpaper: Yup.string().required('Image is Required'),
      price: Yup.number().typeError('Must be number').required('Required'),
      quantity: Yup.number().typeError('Must be number').required('Required'),
    })
  ),
});

export const addEditRewardSchema = Yup.object().shape({
  type: Yup.mixed().required('Required'),
  title: Yup.string().required('Required'),
  shortDescription: Yup.string().required('Required'),
  descriptionPoints: Yup.array().of(Yup.string().required('Required')),
  price: Yup.number().typeError('Must be number').required('Required'),
  imageLink: Yup.string().required('Image is Required'),
});
export const addEditWithPartnerRewardSchema = Yup.object().shape({
  type: Yup.mixed().required('Required'),
  title: Yup.string().required('Required'),
  shortDescription: Yup.string().required('Required'),
  descriptionPoints: Yup.array().of(Yup.string().required('Required')),
  price: Yup.number().typeError('Must be number').required('Required'),
  imageLink: Yup.string().required('Image is Required'),
  partnerId: Yup.object().required('Required').nullable(),
});
export const addEditSeriesSchema = Yup.object().shape({
  name: Yup.mixed().required('Required'),
  endDateTime: Yup.string().required('Required'),
  partnerId: Yup.object().required('Required'),
  contestIds: Yup.array().required('Required'),
});
export const addEditRewardTypesSchema = Yup.object().shape({
  contestId: Yup.mixed().required('Required'),
  numberOfPositions: Yup.number().typeError('Must be number').required('Required'),
  rowData: Yup.array().of(
    Yup.object().shape({
      positions: Yup.array().min(1, 'Required').required('Required'),
      rewardId: Yup.mixed().required('Required'),
      expiryDate: Yup.string().nullable().required('Required'),
      redeemTime: Yup.number().typeError('Must be number').required('Required'),
      winningPoints: Yup.number().typeError('Must be number').required('Required'),
    })
  ),
});

export const addEditSpecialQuizSchema = Yup.object().shape({
  contestId: Yup.number(),
  rowData: Yup.array().of(
    Yup.object().shape({
      questions: Yup.array().min(1, 'Required').required('Required'),
      // .test("test", "Duplicate questions Ids exist here!", (value, ctx) => {

      //   let relevantArrayB = ctx?.from?.[1]?.value?.rowData?.filter((_, index) => index !== ctx?.options?.index);
      //   let arrayBValues = relevantArrayB.flatMap(obj => obj.questions.map(q => q.value));

      //   const foundInRows = [];
      //     value?.forEach(val => {
      //       if (arrayBValues?.includes(val?.value)) {
      //         foundInRows.push(val?.value);
      //       }
      //   })

      //   if (foundInRows.length > 0) {
      //     return false;
      //   }
      //   return true;
      // })
      releasePeriod: Yup.string().required('Required'),
    })
  ),
});

///////////////////////// REWARD RULES EDIT SCHEMA///////////////////////

export const editRewardRulesSchema = Yup.object().shape({
  Contest: Yup.mixed().required('Required'),
  position: Yup.number().typeError('Must be number').required('Required'),
  expiryDate: Yup.string().nullable().required('Required'),
  redeemTime: Yup.number().typeError('Must be number').required('Required'),
  RewardType: Yup.mixed().required('Required'),
});

///////////////////////// CONE REWARD MODAL SCHEMA///////////////////////

export const cloneRewardSchema = Yup.object().shape({
  contestValue: Yup.mixed().required('Required'),
});

///////////////////////// ASSIGNED REWARD TO USER///////////////////////

export const assignRewardSchema = Yup.object().shape({
  Contest: Yup.mixed().required('Required'),
  expiryDate: Yup.string().nullable().required('Required'),
  RewardType: Yup.mixed().required('Required'),
});

///////////////////////// GAMES PAGE MENU SPEICLA QUIZ SCHEMA///////////////////////

export const gameQuizSchema = Yup.object().shape({
  specialQuizType: Yup.mixed().required('Required'),
});

export const teamSchema = Yup.object().shape({
  league_id: Yup.object().required('Required'),
  teamName: Yup.string().required('Required'),
  teamAlias: Yup.string().required('Required'),
  image_url: Yup.string().required('Image is Required'),
});

export const gameSchema = Yup.object().shape({
  leagueId: Yup.object().required('Required'),
  homeTeamId: Yup.object().required('Required'),
  awayTeamId: Yup.object().required('Required'),
  startTime: Yup.string().required('Required').nullable(),
  status: Yup.object().required('Required'),
});

export const instructionsSchema = Yup.object().shape({
  instructionType: Yup.string().required('Instruction Type is required'),
  topText: Yup.string().required('Top Text is required'),
  bottomText: Yup.string().required('Bottom Text is required'),
  buttonText: Yup.string().required('Button Text is required'),
  url: Yup.string().required('Image URL is required'),
});

export const newNotificationsSchema = Yup.object().shape({
  notificationType: Yup.object().required('Notification Type is required'),
  greetingText: Yup.string().required('Greeting Text is required'),
  bodyText: Yup.string().required('Text is required'),
  buttonText: Yup.string().required('Text is required'),
  emailSubject: Yup.string().required('Email Subject is required'),
  partnerId: Yup.object().required('Single Partner is required'),
  pushNotificationText: Yup.string().required('Push Notification Text is required'),
  scheduleDateTime: Yup.date().required('Schedule Date Time is required'),
});

export const sendEmailFollowupSchema = Yup.object().shape({
  sendAll: Yup.boolean().test('exclusive-toggles', 'one toggle should be true', function (value) {
    const sendContestUserNotifications = this.parent.sendContestUserNotifications;
    return (
      (sendContestUserNotifications === false && value === true) ||
      (sendContestUserNotifications === true && value === false)
    );
  }),
  sendContestUserNotifications: Yup.boolean().test('exclusive-toggles', 'one toggle should be true', function (value) {
    const sendAll = this.parent.sendAll;
    return (sendAll === false && value === true) || (sendAll === true && value === false);
  }),
});
