import Iconify from 'src/components/Iconify';
import { ROUTES } from 'src/router/routes';

export const GoogleMapsApiKey = 'AIzaSyDRAId6pp-CzNcrm1NK9BPnUtGpjyaldBw';

export const IMAGE_MAX_SIZE = 30000000;

export const contestTypes = [
  { label: 'GLOBAL', value: 1 },
  { label: 'LOCATION BASED', value: 2 },
  { label: 'LOCATION + GLOBAL SHOW', value: 3 },
  { label: 'LINK BASED', value: 4 },
];

export const QUESTION_TO_SHOW_CONSTANT = [
  { label: 'Odds only', value: 1 },
  { label: 'No-Odds only', value: 2 },
  { label: 'Both', value: 3 },
];

export const typedata = [
  { type: '1', reward: 'XP' },
  { type: '2', reward: 'Wallpaper' },
  { type: '3', reward: 'Food, Beverage, Appetizer' },
  { type: '4', reward: 'Physical Good' },
  { type: '5', reward: 'Physical Gift Certificate' },
  { type: '6', reward: 'Virtual Gift Certificate' },
  { type: '7', reward: 'NFT Reward' },
  { type: '8', reward: 'Partner Reward' },
];

export let contestWinnerListTableHeading = [
  { id: 'option', label: 'Option', alignRight: false },
  { id: 'rank', label: 'Rank', alignRight: false },
  { id: 'score', label: 'Score', alignRight: false },
  { id: 'user', label: 'User', alignRight: false },
  { id: 'reward', label: 'Reward', alignRight: false },
  { id: 'reward_type', label: 'Reward Type', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  { id: 'action_date', label: 'Action Date', alignRight: false },
  { id: 'finalRedeem', label: 'Final Redeem', alignRight: false },
  { id: 'expiry_date', label: 'Expiry Date', alignRight: false },
];

export const seriesWinnerListTableHeading = [
  // { id: 'option', label: 'Option', alignRight: false },
  { id: 'rank', label: 'Rank', alignRight: false },
  { id: 'score', label: 'Score', alignRight: false },
  { id: 'user', label: 'User', alignRight: false },
  { id: 'reward', label: 'Reward', alignRight: false },
  { id: 'reward_type', label: 'Reward Type', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  { id: 'action_date', label: 'Action Date', alignRight: false },
  { id: 'finalRedeem', label: 'Final Redeem', alignRight: false },
  { id: 'expiry_date', label: 'Expiry Date', alignRight: false },
];

export const UserContestHistoryListTableHeading = [
  { id: 'rank', label: 'Rank', alignRight: false },
  { id: 'score', label: 'Score', alignRight: false },

  { id: 'contestData', label: 'Contest', alignRight: false },
  { id: 'createdAt', label: 'Entry Date', alignRight: false },
  { id: 'reward_type', label: 'Reward Type', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  { id: 'action_date', label: 'Action Date', alignRight: false },
  { id: 'finalRedeem', label: 'Final Redeem', alignRight: false },
  { id: 'expiry_date', label: 'Expiry Date', alignRight: false },
];

export const UserPartnerPointsListTableHeading = [
  { id: 'partnerData', label: 'Partner Name', alignRight: false },
  { id: 'totalPoints', label: 'Total Points Win/Assigned', alignRight: false },
  { id: 'redeemPoints', label: 'Redeemed Points', alignRight: false },
  { id: 'balance', label: 'Balance Points', alignRight: false },
];

export const GAMES_TABLE_HEAD = [
  { id: '', label: '' },
  { id: 'teamName', label: 'Game Name', alignRight: false },
  { id: 'league', label: 'League', alignRight: false },
  { id: 'startTime', label: 'Start Time', alignRight: false },
  { id: 'quizActive', label: 'Quiz Active', alignRight: false },
  { id: 'gameOfWeek', label: 'Game Of Week', alignRight: false },
  { id: 'awayPoints', label: 'Away Points', alignRight: false },
  { id: 'homePoints', label: 'Home Points', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'quarter', label: 'Quarter', alignRight: false },
  { id: 'duration', label: 'Duration', alignRight: false },
  { id: 'userPlayCount', label: 'User Play Count', alignRight: false },
  { id: 'sportId', label: 'Sport ID', alignRight: false },
  { id: 'gameFeedId', label: 'Game Feed ID', alignRight: false },
  { id: 'broadcast', label: 'Broadcast', alignRight: false },
];

export const TEAMS_TABLE_HEAD = [
  { id: '', label: '' },
  { id: 'teamName', label: 'Team Name', alignRight: false },
  { id: 'leagues', label: 'League Name', alignRight: false },
  { id: 'teamAlias', label: 'Team Alias', alignRight: false },
  { id: 'hashTag', label: 'Team HashTags', alignRight: false },
  { id: 'market', label: 'Market', alignRight: false },
];

export const SERIES_TABLE_HEAD = [
  { id: '', label: '' },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'partnerId', label: 'Partner', alignRight: false },
  { id: 'isActive', label: 'Active', alignRight: false },
  { id: 'endDateTime', label: 'End Date Time', alignRight: false },
];

export const gameQuizListTableHeading = [
  { id: 'option', label: 'Option', alignRight: false },
  { id: 'innings', label: 'Innings', alignRight: false },
  { id: 'additionalData', label: 'Additional Data', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
];
export const GAME_QUIZ_TYPE_OPTIONS = [
  { label: 'All at once', value: 1 },
  { label: 'Per Quarter/inning', value: 2 },
];

export const pieChartColors = [
  '#fc2659',
  '#80c4ff',
  '#6bf656',
  '#ee5b13',
]
export const statsHeaderOptions = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'question', label: 'Question', alignRight: false },
  { id: 'options', label: 'Response (%)', alignRight: false },
  { id: 'graph', label: 'Response Graph', alignRight: false },
  { id: 'options1', label: 'Response (Count #)', alignRight: false },
  { id: 'correct', label: 'Correct Ans.', alignRight: false },
  // { id: 'correctPercentage', label: 'Correct (%)', alignRight: false },
  
];

export const toolTipMessage = {
    viewImage : 'Click to view',
}

export const DATE_FORMAT = 'MMM-DD-YYYY';
export const DATE_FORMAT_TIME = 'MMM-DD-YYYY h:mm a';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
export const INITIAL_SUB_ADMIN_ACCESS = [
  {
    Id: 'users',
    title: 'Users list',
    name: 'usersList',
    path: ROUTES.USERS,
    icon: getIcon('eva:people-fill'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'users',
    title: 'User Wallets',
    name: 'wallets',
    path: ROUTES.USERS_WALLET,
    icon: getIcon('akar-icons:wallet'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'users',
    title: 'Reported Users',
    name: 'reported',
    path: ROUTES.REPORTED_USERS,
    icon: getIcon('ic:round-report'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'contests',
    title: 'Contest List',
    name: 'contests',
    path: ROUTES.CONTESTS,
    icon: getIcon('akar-icons:wallet'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'contests',
    title: 'Contest Form',
    name: 'form',
    path: ROUTES.CONTESTS_FORMS,
    icon: getIcon('akar-icons:wallet'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'contests',
    title: 'Contest Wallpapers',
    name: 'wallpapers',
    path: ROUTES.CONTESTS_WALLPAPERS,
    icon: getIcon('akar-icons:wallet'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'contests',
    title: 'Wallpaper Purchase',
    name: 'purchase',
    path: ROUTES.WALLPAPER_PURCHASE,
    icon: getIcon('icon-park-solid:buy'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'contests',
    title: 'Contest Instructions',
    name: 'instructions',
    path: ROUTES.CONTESTS_INSTRUCTIONS,
    icon: getIcon('akar-icons:wallet'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'series',
    title: 'Series List',
    name: 'list',
    path: ROUTES.SERIES,
    icon: getIcon('akar-icons:wallet'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'partners',
    title: 'Partners List',
    name: 'list ',
    path: ROUTES.PARTNER,
    icon: getIcon('wpf:administrator'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'gameModels',
    title: 'Question Generator',
    name: 'generator',
    path: ROUTES.OPEN_AI_GENERATOR,
    icon: getIcon('wpf:administrator'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'gameModels',
    title: 'Question Bank',
    name: 'question',
    path: ROUTES.QUESTION_BANK,
    icon: getIcon('eos-icons:admin'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'rewardTypes',
    title: 'Reward Types',
    name: 'types',
    path: ROUTES.REWARDTYPES,
    icon: getIcon('eva:file-text-fill'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'rewardRules',
    title: 'Reward Rules',
    name: 'reward',
    path: ROUTES.REWARD_POSITION,
    icon: getIcon('eva:lock-fill'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'games',
    title: 'Games List',
    path: ROUTES.GAMES,
    icon: getIcon('akar-icons:wallet'),
    name: 'games',
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'games',
    title: 'Teams List',
    name: 'teams',
    path: ROUTES.TEAMS,
    icon: getIcon('akar-icons:wallet'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'admins',
    title: 'Admins',
    name: 'admins',
    path: ROUTES.ADMINS,
    icon: getIcon('eos-icons:admin'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'content',
    title: 'News Letter',
    name: 'news',
    path: ROUTES.NFTNEWSLETTER,
    icon: getIcon('mdi:email-newsletter'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'content',
    title: 'Support Request ',
    name: 'support',
    path: ROUTES.SUPPORT_REQUEST,
    icon: getIcon('akar-icons:wallet'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'nftPurchase',
    title: 'NFT Purchase',
    name: 'nft',
    path: ROUTES.NFTPURCHASE,
    icon: getIcon('carbon:purchase'),
    read: false,
    edit: false,
    delete: false,
  },
  {
    Id: 'faqs',
    title: 'FAQs',
    name: 'faqs',
    path: ROUTES.FAQ,
    icon: getIcon('mdi:email-newsletter'),
    read: false,
    edit: false,
    delete: false,
  },
];

