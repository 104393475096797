import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Box,
  Grid,
  Button,
  IconButton,
  colors,
  Divider,
} from '@mui/material';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { APIs } from '../../apis';
import debounce from 'lodash/debounce';
import { addEditApi, listApi } from 'src/apis/admin';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addEditRewardTypesSchema } from 'src/utils/validationSchemas';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { ROUTES } from 'src/router/routes';
import { toast } from 'react-toastify';
import { handleRewardType } from '../../utils/helper';
import moment from 'moment';
import './index.css';
import React from 'react';
import BackButton from 'src/components/backButton';

const emptyData = {
  positions: [],
  rewardId: '',
  expiryDate: '',
  redeemTime: '',
  winningPoints: '',
};

const initialValues = {
  contestId: [],
  numberOfPositions: '',
  singleValueSelected: '',
  rowData: [
    {
      positions: [],
      rewardId: '',
      expiryDate: '',
      redeemTime: '',
      winningPoints: '',
    },
  ],
};

export default function addEditRewardRules({ isEdit }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectid = searchParams.get('intowhichcontestid');
  const [loading, setLoading] = useState(false);
  const [rowDataAgain, setRowDataAgain] = useState();
  const [positionOptions, setPositionOptions] = useState([]);

  useEffect(() => {
    if (id && selectid) {
      setLoading(true);
      listApi(APIs.LIST_REWARD_POSITIONS, { contestId: JSON.stringify([selectid]) }).then((result) => {
        listApi(APIs.LIST_REWARD_POSITIONS, { contestId: JSON.stringify([id]) })
          .then((res) => filterData(res, result?.data?.[0]))
          .finally(() => setLoading(false));
      });
    }
  }, [id]);

  const filterData = (result, selectedData) => {
    const rewardIdArray = result?.data?.map((items) => items.rewardId);
    const uniqueRewardIds = rewardIdArray.filter((item, index) => rewardIdArray.indexOf(item) === index);
    let temp = [];

    const positionArray = uniqueRewardIds.map((item) =>
      result?.data
        ?.filter((items) => items.rewardId === item)
        .map((it) => {
          return {
            label: it.position,
            value: it.position,
          };
        })
    );

    let dataArray = result?.data?.filter((item) => {
      if (uniqueRewardIds.includes(item.rewardId) && !temp.includes(item.rewardId)) {
        temp.push(item.rewardId);
        return true;
      }
    });
    const resultArray = dataArray.map((data, index) => {
      let typealpha = handleRewardType(data?.RewardType?.type);
      const rewardData = {
        label: (
          <div className="optionStyles">
            <img src={data?.RewardType?.imageLink} height="30px" width="30px" />
            <div className="selectText">
              {data?.RewardType?.title} |{typealpha} |{data?.RewardType?.price}
            </div>
          </div>
        ),
        value: data?.RewardType?.id,
      };

      return {
        positions: positionArray?.[index],
        rewardId: rewardData,
        expiryDate: new Date(data?.expiryDate),
        redeemTime: data?.redeemTime,
      };
    });

    const contestData = [
      {
        label: (
          <div className="optionStyles">
            <img src={selectedData?.Contest?.imageLink} height="30px" width="30px" />
            <div className="selectText">
              {selectedData?.Contest?.name} |{' '}
              {moment(selectedData?.Contest?.startDateTime).format('DD-MMM-YYYY  hh:mm a')}
            </div>
          </div>
        ),
        value: selectedData?.Contest?.id,
      },
    ];

    let groupData = {
      contestId: contestData,
      numberOfPositions: result?.data?.length,
      singleValueSelected: '',
      rowData: resultArray,
    };
    setRowDataAgain(groupData);
  };

  const promiseContestDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_CONTESTS, { search: inputValue}).then((result) => {
      const filter = result?.data.map((item) => ({
        label: (
          <div className="optionStyles">
            <img src={item?.imageLink} height="30px" width="30px" />
            <div className="selectText">
              {item.name} | {moment(item.startDateTime).format('DD-MMM-YYYY  hh:mm a')}
            </div>
          </div>
        ),
        value: item.id,
      }));
      resolve(filter);
    });
  };
  const loadContestData = debounce(promiseContestDataOptions, 300);

  const promiseReawrdDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_REWARD_TYPES, { search: inputValue }).then((result) => {
      const filter = result?.data.map((item) => {
        let typealpha = handleRewardType(item?.type);
        let option = {
          label: (
            <div className="optionStyles">
              <img src={item?.imageLink} height="30px" width="30px" />
              <div className="selectText">
                {item?.title} | {item?.shortDescription} | {typealpha} | {item?.price}$
              </div>
            </div>
          ),
          name: `${item?.title | item?.shortDescription | typealpha | item?.price}$`,
          value: item?.id,
        };
        return option;
      });
      resolve(filter);
    });
  };

  const loadRewardTypes = debounce(promiseReawrdDataOptions, 300);

  const handleFormSubmit = (formikdata) => {
    const data = formikdata?.rowData?.map((item) => ({
      positions: item?.positions.map(({ value }) => value),
      rewardId: item?.rewardId?.value,
      expiryDate: item?.expiryDate,
      redeemTime: item?.redeemTime,
      winningPoints: item?.winningPoints,
    }));
    let contestId = formikdata?.contestId?.map(({ value }) => value);
    addEditApi(APIs.ADD_EDIT_REWARD_RULES, { data, contestId }).then((result) => {
      navigate(ROUTES.REWARD_POSITION);
      toast.success('Added Successfully');
    });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: rowDataAgain || initialValues,
    validationSchema: addEditRewardTypesSchema,
    onSubmit: handleFormSubmit,
  });

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;
  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 55,
        border: '',
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2,
      };
    },
  };

  useEffect(() => {
    let option = [];
    for (let i = 1; i <= values.numberOfPositions; i++) {
      option.push({
        label: i,
        value: i,
      });
    }

    setPositionOptions(option);
  }, [values?.numberOfPositions]);

  return (
    <Page title="Dashboard: Blog" loading={loading}>
      <Container>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Rewards Rules
              </Typography>
              <div>
                <BackButton onClick={() => navigate(ROUTES.REWARD_POSITION)} />
                <Button variant="contained" type="submit" startIcon={<Iconify icon="eva:save-outline" />}>
                  Save Rules
                </Button>
              </div>
            </Stack>

            <Card sx={{ minHeight: '550px' }}>
              <Box sx={{ flexGrow: 1, padding: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      isMulti
                      placeholder="Select Contests"
                      styles={customStyles}
                      loadOptions={loadContestData}
                      value={values?.contestId}
                      onChange={(data) => {
                        setFieldValue('contestId', data);
                      }}
                    />
                    {Boolean(touched.contestId && errors.contestId) && (
                      <Typography variant="caption" display="block" gutterBottom color={'red'}>
                        {errors.contestId}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Total Number of Positions"
                      {...getFieldProps('numberOfPositions')}
                      error={Boolean(touched.numberOfPositions && errors.numberOfPositions)}
                      helperText={touched.numberOfPositions && errors.numberOfPositions}
                    />
                  </Grid>

                  <Grid container padding={2} spacing={2}>
                    <FieldArray
                      name="rowData"
                      render={(arrayHelpers) => (
                        <>
                          {values?.rowData?.map((item, index) => (
                            <Fragment key={index}>
                              <Grid item xs={6} md={4}>
                                <Select
                                  name={`rowData.${index}.positions`}
                                  isMulti
                                  placeholder="Select Positions"
                                  options={[{ value: positionOptions, label: 'All' }, ...positionOptions]}
                                  styles={customStyles}
                                  value={values?.rowData?.[index]?.positions}
                                  onChange={(data) => {
                                    data?.find((option) => option?.label === 'All')
                                      ? setFieldValue(`rowData.${index}.positions`, positionOptions)
                                      : setFieldValue(`rowData.${index}.positions`, data);
                                  }}
                                />
                                {errors?.rowData?.[index]?.positions && touched?.rowData?.[index]?.positions && (
                                  <div className="field-error">{errors?.rowData?.[index]?.positions}</div>
                                )}
                              </Grid>
                              <Grid item xs={6} md={8}>
                                <AsyncSelect
                                  name={`rowData.${index}.rewardId`}
                                  cacheOptions
                                  defaultOptions
                                  placeholder="Select Reward Title/Short Description"
                                  loadOptions={loadRewardTypes}
                                  value={values?.rowData[index]?.rewardId}
                                  styles={customStyles}
                                  isClearable
                                  onChange={(data) => {
                                    setFieldValue(`rowData.${index}.rewardId`, data);
                                  }}
                                />
                                {errors?.rowData?.[index]?.rewardId && touched?.rowData?.[index]?.rewardId && (
                                  <div className="field-error">{errors?.rowData?.[index]?.rewardId}</div>
                                )}
                              </Grid>
                              <Grid item xs={3} md={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DesktopDatePicker
                                    value={values?.rowData[index].expiryDate}
                                    label="Expiry date"
                                    inputFormat="MM/dd/yyyy"
                                    onChange={(date) => setFieldValue(`rowData.${index}.expiryDate`, date)}
                                    disablePast={true}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        error={Boolean(
                                          touched?.rowData?.[index]?.expiryDate && errors?.rowData?.[index]?.expiryDate
                                        )}
                                        helperText={
                                          touched?.rowData?.[index]?.expiryDate && errors?.rowData?.[index]?.expiryDate
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={3} md={3}>
                                <TextField
                                  fullWidth
                                  value={values?.rowData[index]?.redeemTime}
                                  type="number"
                                  name={`rowData.${index}.redeemTime`}
                                  label={index === 0 ? 'Redeem Time (in Mins)' : ''}
                                  placeholder={
                                    index === 0 ? '1. Redeem Time (in Mins)' : `${index + 1}. Redeem Time (in Mins)`
                                  }
                                  onChange={(e) =>
                                    setFieldValue(`rowData.${index}.redeemTime`, parseInt(e.target.value))
                                  }
                                  error={Boolean(
                                    touched?.rowData?.[index]?.redeemTime && errors?.rowData?.[index]?.redeemTime
                                  )}
                                  helperText={
                                    touched?.rowData?.[index]?.redeemTime && errors?.rowData?.[index]?.redeemTime
                                  }
                                />
                              </Grid>
                              <Grid item xs={3} md={3}>
                                <TextField
                                  fullWidth
                                  value={values?.rowData[index]?.winningPoints}
                                  type="number"
                                  name={`rowData.${index}.winningPoints`}
                                  label={index === 0 ? 'Winning Points' : ''}
                                  placeholder={index === 0 ? '1. Winning Points ' : `${index + 1}. Winning Points`}
                                  onChange={(e) =>
                                    setFieldValue(`rowData.${index}.winningPoints`, parseInt(e.target.value))
                                  }
                                  error={Boolean(
                                    touched?.rowData?.[index]?.winningPoints && errors?.rowData?.[index]?.winningPoints
                                  )}
                                  helperText={
                                    touched?.rowData?.[index]?.winningPoints && errors?.rowData?.[index]?.winningPoints
                                  }
                                />
                              </Grid>

                              <Grid item xs={2} md={1}>
                                {values?.rowData?.length !== 1 && (
                                  <IconButton
                                    size="large"
                                    sx={{ ml: 2, color: colors.red[500] }}
                                    onClick={() => {
                                      return arrayHelpers.remove(index);
                                    }}
                                  >
                                    <Iconify icon="eva:minus-square-fill" />
                                  </IconButton>
                                )}
                                <Grid item xs={2} md={4}>
                                  <IconButton
                                    size="large"
                                    sx={{ ml: 2, color: 'primary.main' }}
                                    onClick={() => {
                                      arrayHelpers.insert(index + 1, emptyData);
                                    }}
                                  >
                                    <Iconify icon="eva:plus-square-fill" />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Fragment>
                          ))}
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
