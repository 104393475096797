import React, { useEffect, useState, Fragment } from 'react';
import './index.css';
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  CircularProgress,
  IconButton,
  colors,
  Grid,
  Switch,
  FormControlLabel,
  TextField,
  Tooltip
} from '@mui/material';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { addEditApi, listApi } from 'src/apis/admin';
import { APIs } from 'src/apis/client';
import { debounce } from 'lodash';
import { addEditSpecialQuizSchema } from 'src/utils/validationSchemas';
import Iconify from 'src/components/Iconify';
import { toast } from 'react-toastify';

const emptyData = {
  questions: [],
  releasePeriod: '',
}
const initialValues = {
  contestId: '',
  rowData: [
    {
      questions: [],
      releasePeriod: '',
    },
  ],
};

const releasePeriod = [
  { label: 'pre', value: 'pre' },
  { label: '1 or top-first', value: '1' },
  { label: '2 or bottom-first', value: '2' },
  { label: '3 or end-second', value: '3' },
  { label: 'half time', value: 'half_time' },
  { label: '4 or end-third', value: '4' },
  { label: '5 or end-fourth', value: '5' },
  { label: '6 or end-fifth', value: '6' },
  { label: '7 or end-sixth', value: '7' },
  { label: '8 or end-eight', value: '8' },
  { label: '9 or end-ninth', value: '9' },
  { label: '9 mid', value: '9m' },
  { label: '10', value: '10' },
]
const COMMONN_FIELD = [
  { label: 'Category', identifier: 'category', type: 'text',  gridSize: 2 },
  { label: 'Brand', identifier: 'brand', type: 'text', gridSize: 2 },
  { label: 'Topic', identifier: 'topic', type: 'text',  gridSize: 2 },
  { label: 'Sub Topic', identifier: 'subtopic', type: 'text',  gridSize: 2 },
  { label: '# Questions', identifier: 'questionsCount', type: 'number', gridSize: 2 },
  { label: '# Quarters (with pre)', identifier: 'quartersCount', type: 'number',  gridSize: 2 },
];
const SpeicalQuizFlowModal = ({ closeModal, selectedContestId, setRefresh }) => {

  const [rulesData, setRulesData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifiedFilter, setVerifiedFilter] = useState(true);
  const [duplicateText, setDuplicateText] = useState([]);
  const [questionCriteria, setQuestionCriteria] = useState({});

  useEffect(() => {
    if (selectedContestId.id) {
      setLoading(true);
      listApi(APIs.SAVE_QUIZ_RULES_FLOW, { contestId: selectedContestId.id })
        .then((result) => {
          if (result?.data && result?.data?.specialQuizRules?.length) {
            const filter = result?.data?.specialQuizRules?.map((item) => {
              const data = item?.questions?.map((questItem) => {
                return getLabelValue(questItem);
              })
              return { questions: data, releasePeriod: item?.releasePeriod };
            });
            setRulesData({ rowData: filter });
          }
        })
        .finally(() => setLoading(false));
    }
  }, [selectedContestId.id]);

  const promiseQuestionOptions = (inputValue, resolve) => {
    listApi(APIs.QUESTION_BANK_LIST, { search: inputValue, fromJsonCreate: true, isVerified: verifiedFilter }).then((result) => {
      const filter = result?.data.map((item) => {
        return getLabelValue(item);
      });
      resolve(filter);
    });
  };

  function getLabelValue(item) {
    return {
      label: (
        <div className="optionStyles">
          <img src={item?.questionHImage} height="30px" width="30px" />
          <div className="selectText">
            {item?.id} | {item?.question}
          </div>
        </div>
      ),
      value: item?.id,
    };
  }

  const loadQuestionOptions = debounce(promiseQuestionOptions, 300);
  const handleFormSubmit = (formikdata) => {

    const data = formikdata?.rowData?.map((item) => ({
      questions: item?.questions?.map(({ value }) => value),
      releasePeriod: item?.releasePeriod,
    }));

    const contestId = selectedContestId?.id;

    addEditApi(APIs.SAVE_QUIZ_RULES_FLOW, { data, contestId }).then((result) => {
      toast.success('Flow Added Successfully! Check updated special Quiz');
      setRefresh((s) => !s);
      closeModal()
    });

  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: rulesData || initialValues,
    validationSchema: addEditSpecialQuizSchema,
    onSubmit: handleFormSubmit,
    validateOnChange: true,
  });

  const { errors, touched, values, handleSubmit, setFieldValue } = formik;

  function checkDuplicate() {

    const valueOccurrences = {};
    formik?.values?.rowData.forEach(item => {
      item.questions.forEach(question => {
        if (!valueOccurrences[question.value]) {
          valueOccurrences[question.value] = [];
        }
        valueOccurrences[question.value].push(item.releasePeriod);
      });
    });
    const textArray = [];
    for (const value in valueOccurrences) {
      if (valueOccurrences[value].length > 1) {
        textArray.push(`${value}, Exists in: ${valueOccurrences[value].join(', ')}`);
      }
    }

    setDuplicateText(textArray);
  }

  useEffect(() => {
    checkDuplicate();
  }, [formik.values]);

  const generateQuestions = ()=>{
    if(questionCriteria && Object.keys(questionCriteria).length){
      setLoading(true);
      setQuestionCriteria({ ...questionCriteria, isVerified : verifiedFilter });

      listApi(APIs.LIST_QUESTIONS_CRITERIA_BASED, questionCriteria)
        .then((response) => {
            if(response?.data && response?.data?.length){
              const filter = response?.data?.map((item) => {
                const data = item?.questions?.map((questItem) => {
                  return getLabelValue(questItem);
                })
                return { questions: data, releasePeriod: item?.releasePeriod };
              });
              setRulesData({ rowData: filter });
              toast.success('Questions Generated Successfully');
            }
        })
        .catch((err) => {
          toast.error(err?.error || 'Something went wrong');
        })
        .finally(() => setLoading(false)); 
    }
    else {
      toast.error('Please fill any of the fields to generate questions');
    }
  }

  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 55,
        border: '',
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2,
      };
    },
  };

  return (
    <div className="modalWinnerContainer" style={{ height: '100%' }}>
      <Container className="modalInnerContainer">
        <div className="modalheadingWinnerrow">
          <Typography variant="h4" gutterBottom>
            {selectedContestId?.name} - {"Create/Special Quiz Flow"}
          </Typography>       
          <FormControlLabel
            control={
              <Switch checked={verifiedFilter} onChange={(event) => setVerifiedFilter(event.target.checked)} />
            }
            label={'Filter Questions By Verified'}
          />
          <Tooltip title="Save Quiz Flow in Special Quiz" placement="top">
            <Button variant="contained" onClick={handleSubmit} startIcon={<Iconify icon="eva:save-outline" />}>
              Save Flow & Speical Quiz
            </Button>
          </Tooltip>
          <Button onClick={closeModal} style={{ marginRight: '10px' }}>
            Cancel
          </Button>        
        </div>

        <Typography variant="h6" sx={{color: 'primary.main'}}>{selectedContestId?.gameIds?.length > 0 ? `Game: ${selectedContestId?.gameIds[0]?.AwayTeam?.teamName} VS ${selectedContestId?.gameIds[0]?.HomeTeam?.teamName}` : '-'}</Typography>

        {/* Filter Section Starts */}
        <Card sx={{ flexGrow: 1, padding: 2, marginTop: 2 }}>
          <Typography variant="h6" gutterBottom>
            {"Generate Questions based on below criteria"}
            <Button variant="contained" style={{float: 'right'}}
                onClick={generateQuestions} startIcon={<Iconify icon="oui:ml-create-population-job" />}>
                Generate Questions
              </Button>
          </Typography>
          <Grid container spacing={2} style={{ paddingTop: '0px' }}>
            <>
              {COMMONN_FIELD.map((field, fieldIndex) => {
                return (
                  <Grid item xs={12} md={field.gridSize} key={fieldIndex}>
                    <TextField
                      fullWidth
                      key={fieldIndex}
                      label={field.label}
                      type={field.type}
                      name={field.identifier}
                      value={field.value}
                      placeholder={field?.placeholder}
                      defaultValue={values?.[field.identifier]}
                      onChange={(e) => {
                        setQuestionCriteria({ ...questionCriteria, [field.identifier]: e.target.value });
                      }}
                    />

                  </Grid>
                )
              })}
            </>
          </Grid>
        </Card>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Card sx={{ flexGrow: 1, padding: 2, height: '60vh', overflowY: 'scroll', marginTop: '10px' }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (

                <Grid container padding={2} spacing={2}>

                  <FieldArray
                    name="rowData"
                    render={(arrayHelpers) => (
                      <>
                        {values?.rowData?.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              <Grid item xs={6} md={8}>
                                <AsyncSelect
                                  isMulti
                                  name={`rowData.${index}.questions`}
                                  cacheOptions
                                  defaultOptions
                                  placeholder="Select/Search Question"
                                  loadOptions={loadQuestionOptions}
                                  value={values?.rowData[index]?.questions}
                                  styles={customStyles}
                                  isClearable
                                  onChange={(data) => {
                                    setFieldValue(`rowData.${index}.questions`, data);
                                  }}
                                />
                                {errors?.rowData?.[index]?.questions && touched?.rowData?.[index]?.questions && (
                                  <div className="field-error">{errors?.rowData?.[index]?.questions}</div>
                                )}

                              </Grid>

                              <Grid item xs={3} md={3}>

                                <Select
                                  options={releasePeriod}
                                  placeholder="Select Release Period"
                                  styles={customStyles}
                                  value={releasePeriod.filter((item) => item?.value === values?.rowData[index]?.releasePeriod)}
                                  defaultValue={values?.rowData[index]?.releasePeriod}
                                  onChange={(data) => {
                                    setFieldValue(`rowData.${index}.releasePeriod`, data?.value);
                                  }}
                                />
                                {errors?.rowData?.[index]?.releasePeriod && touched?.rowData?.[index]?.releasePeriod && (
                                  <div className="field-error">{errors?.rowData?.[index]?.releasePeriod}</div>
                                )}
                              </Grid>

                              <Grid item xs={2} md={1}>
                                {values?.rowData?.length && (
                                  <IconButton
                                    size="large"
                                    sx={{ ml: 2, color: colors.red[500] }}
                                    onClick={() => {
                                      return arrayHelpers.remove(index);
                                    }}
                                  >
                                    <Iconify icon="eva:minus-square-fill" />
                                  </IconButton>
                                )}
                                <Grid item xs={2} md={4}>
                                  <IconButton
                                    size="large"
                                    sx={{ ml: 2, color: 'primary.main' }}
                                    onClick={() => {
                                      arrayHelpers.insert(index + 1, emptyData);
                                    }}
                                  >
                                    <Iconify icon="eva:plus-square-fill" />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Fragment>
                          )
                        }

                        )}
                      </>
                    )}
                  />
                  {
                    !!duplicateText.length && <div style={{ color: 'red', marginTop: '40px' }}>
                      {duplicateText.map((d, index) => <p key={index}>{d}</p>)}
                    </div>
                  }
                </Grid>
              )}

            </Card>
          </Form>

        </FormikProvider>

      </Container>
    </div>
  );
};

export default SpeicalQuizFlowModal;
