import React, { useEffect, useState } from 'react';
import './index.css';
import { Typography, Grid, Button, Switch, Tooltip } from '@mui/material';
import Select from 'react-select';
import { useFormik, Form, FormikProvider } from 'formik';
import { gameQuizSchema } from 'src/utils/validationSchemas';
import Iconify from 'src/components/Iconify';
import { GAME_QUIZ_TYPE_OPTIONS } from 'src/contants/contants';
import { APIs } from 'src/apis/client';
import { addEditApi } from 'src/apis/admin';
import { toast } from 'react-toastify';

import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
const initialValues = {
  specialQuiz: false,
  specialQuizType: '',
};

/*Styles from React Select */

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 55,
      border: '',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};

const QuiModal = ({ closeModal, selectedGame, setRefresh }) => {
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    let SelectedData = {
      specialQuiz: selectedGame?.specialQuiz,
      specialQuizType: selectedGame?.specialQuizType,
      specialQuizData: selectedGame?.specialQuizData,
    };
    setSelectedData(SelectedData);
  }, []);
  /*FORM SUBMIT HANDLER */

  const handleFormSubmit = (payload) => {
    let ApiPayload = {
      specialQuiz: payload?.specialQuiz,
      specialQuizType: payload?.specialQuizType,
      id: selectedGame?.id,
    };

    if (payload?.specialQuizData) {
      ApiPayload.specialQuizData = payload?.specialQuizData;
    }
    if (!payload?.specialQuizData) {
      ApiPayload.specialQuizData = [];
    }

    addEditApi(APIs.ADD_EDIT_CONTEST, { ...ApiPayload }).then(() => {
      toast.success('Updated Successfully');
      setRefresh((s) => !s);
      closeModal();
    });
  };

  const updateImagesUrl = (setFieldValue) =>{
    if(selectedData?.specialQuizData && selectedData?.specialQuizData?.length){

      addEditApi(APIs.UPDATE_SPECIAL_QUIZ_URLS, { questions : selectedData?.specialQuizData })
      .then((response) => {
        toast.success('URLs Updated Successfully');
        console.log(response?.data);
        setFieldValue('specialQuizData', response?.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
    }
  }

  /*FORMIK INTIMIALIZATION */

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: selectedData || initialValues,
    validationSchema: gameQuizSchema,
    onSubmit: handleFormSubmit,
  });

  /*DISTRCUTING FORMIK  */
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit}>
        <div className="modalContainer">
          <div className="modalheadingrow">
            <Typography variant="h4" gutterBottom>
              {`${selectedGame?.name} - Special Quiz`}
            </Typography>
          </div>
          <Grid className="formContainer">
            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
              <Grid item xs={8}>
                <Typography> Enable/Disable - Quiz</Typography>
              </Grid>
              <Grid item xs={4}>
                <Switch
                  checked={values?.specialQuiz}
                  onChange={(date) => {
                    setFieldValue('specialQuiz', date?.target?.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ paddingTop: '25px' }}>
              <div className="labelStyle">Special Quiz Type</div>
              <Select
                options={GAME_QUIZ_TYPE_OPTIONS}
                placeholder="Special Quiz Type"
                styles={customStyles}
                value={GAME_QUIZ_TYPE_OPTIONS.filter((item) => item?.value === values?.specialQuizType)}
                defaultValue={values?.specialQuizType}
                onChange={(data) => {
                  setFieldValue('specialQuizType', data?.value);
                }}
              />
              {Boolean(touched.specialQuizType && errors.specialQuizType) && (
                <Typography variant="caption" display="block" gutterBottom color={'red'}>
                  {errors.specialQuizType}
                </Typography>
              )}
            </Grid>
            <Grid sx={{ paddingTop: '25px' }}>
              <Typography>Special Quiz Data</Typography>
              <JSONInput
                name="specialQuizData"
                colors={{ background: '#fefffe', default: 'black', colon: 'black', keys: '#386fa4' }}
                style={{
                  body: { fontSize: 14 },
                  contentBox: { backgroundColor: 'white', border: '2px solid grey', color: 'grey' },
                  warningBox: { backgroundColor: 'lightyellow' },
                }}
                locale={locale}
                height="300px"
                width="100"
                placeholder={values?.specialQuizData}
                onChange={(data) => {
                  setFieldValue('specialQuizData', data?.jsObject ?? null);
                }}
              />
            </Grid>
          </Grid>

          <div className="modalFooterContainer">
            <Typography variant="h4" gutterBottom>
              <Tooltip title="Update Serp URLs to Own URLs">
              <Button variant="contained" 
              onClick={()=> updateImagesUrl(setFieldValue)}
              style={{ marginRight: '180px' }} 
              startIcon={<Iconify icon="eva:save-outline" />}>
                Update Images Url
              </Button>
              </Tooltip>

              <Button onClick={closeModal} style={{ marginRight: '10px' }}>
                Cancel
              </Button>

              <Button variant="contained" type="submit" startIcon={<Iconify icon="eva:save-outline" />}>
                Save Quiz
              </Button>
            </Typography>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default QuiModal;
