import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Card,
  TextField,
  Grid,
  Button,
  Typography,
  Stack,
  Avatar,
  CircularProgress,
  FormControlLabel,
  Switch,
  Tooltip,
  Checkbox,
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import { listApi, addEditApi } from 'src/apis/admin';
import { APIs } from 'src/apis';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import CachedIcon from '@mui/icons-material/Cached';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SkipNextSharpIcon from '@mui/icons-material/SkipNextSharp';
import SkipPreviousSharpIcon from '@mui/icons-material/SkipPreviousSharp';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { confirmAlert } from 'react-confirm-alert';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import ImageModal from './imageModal';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { CHAT_TYPE_OPTIONS, TWITTER_TYPE_OPTIONS } from './constants';
import { debounce } from 'lodash';

export const questionEditSchema = Yup.object().shape({
  type: Yup.mixed().required('Required'),
  questionType: Yup.mixed().required('Required'),
  answerCalculated: Yup.mixed().required('Required'),
  type2: Yup.mixed().required('Required'),
  twitterType: Yup.mixed(),
  question: Yup.string().required('Required'),
  options: Yup.array().of(
    Yup.object().shape({
      choice: Yup.string().required('Required'),
    })
  ),
  explanation: Yup.string(),
  category: Yup.string().required('Required'),
  brand: Yup.string().required('Required'),
  timer: Yup.number(),
  timerPoints: Yup.number(),
  points: Yup.number().required('Required'),
  // chatType: Yup.string().required('Required'),
  difficultyLevel: Yup.string().required('Required'),
  correct: Yup.array()
    .of(Yup.number().required('A selection is required'))
    .min(1, 'At least one selection is required')
    .required('This field is required'),
});

const BUTTTON_COSNTANT = [
  {
    label: 'Cancel',
    variant: 'outlined',
    type: 'button',
    style: { marginLeft: '20px' },
    identifier: 1,
    startIcon: <CachedIcon />,
  },
  {
    label: 'Update',
    variant: 'contained',
    type: 'submit',
    identifier: 2,
    startIcon: <CheckCircleIcon />,
    style: { marginLeft: '20px' },
  },
];

export const TYPE_OPTIONS = [
  { label: 'WITHOUT ODDS', value: 'WITHOUT_ODDS' },
  { label: 'GENERAL TYPE', value: 'GENERAL_TYPE' },
  // { label: 'IMAGE TYPE', value: 'IMAGE_TYPE' },
];

export const QUESTION_TYPE = [
  { label: 'Non - Skill', value: '2' },
  { label: 'Skill Type', value: '1' },
];

export const ANSWERE_TYPE = [
  { label: 'NOW', value: 'NOW' },
  { label: 'EOG', value: 'EOG' },
  { label: 'EOP', value: 'EOP' },
];

export const TYPE_TWO = [
  { label: 'Image Type', value: 'IMAGE_TYPE' },
  { label: 'General', value: 'GENERAL_TYPE' },
];

const GENERATED_CONTANT = {
  HEADER_IMAGE: 'headerImage',
};

const COMMONN_FIELD = [
  { label: 'Type', identifier: 'type', options: TYPE_OPTIONS, gridSize: 3 },
  { label: 'Type 2', identifier: 'type2', options: TYPE_TWO, gridSize: 3 },
  { label: 'Question Type', identifier: 'questionType', options: QUESTION_TYPE, gridSize: 3 },
  { label: 'Answer Calculated', identifier: 'answerCalculated', options: ANSWERE_TYPE, gridSize: 3 },
  { label: 'Category', identifier: 'category', type: 'text', isBasic: true, gridSize: 2 },
  { label: 'Sub Category', identifier: 'subCategory', type: 'text', isBasic: true, gridSize: 2 },
  { label: 'Brand', identifier: 'brand', type: 'text', isBasic: true, gridSize: 2 },
  { label: 'Topic', identifier: 'topic', type: 'text', isBasic: true, gridSize: 3 },
  { label: 'Sub Topic', identifier: 'subtopic', type: 'text', isBasic: true, gridSize: 3 },
  { label: 'Timer', identifier: 'timer', type: 'number', isBasic: true, gridSize: 2 },
  { label: 'TimerPoints', identifier: 'timerPoints', type: 'number', isBasic: true, gridSize: 2 },
  { label: 'Points', identifier: 'points', type: 'number', isBasic: true, gridSize: 2 },

  { label: 'Difficulty Level', identifier: 'difficultyLevel', type: 'text', isBasic: true, gridSize: 2 },
  { label: 'Release Period', identifier: 'releasePeriod', type: 'text', isBasic: true, gridSize: 2 },

  { label: 'Generate Image Auto', identifier: 'generateImageAuto', isSwitch: true, gridSize: 2 },
  { label: 'Verify Question', identifier: 'isVerified', isSwitch: true, gridSize: 2 },
  { label: 'Source Url', identifier: 'source', type: 'text', isBasic: true, gridSize: 4 },
  { label: 'Source Name', identifier: 'sourceName', type: 'text', isBasic: true, gridSize: 3},
  {
    label: 'Chat Message',
    identifier: 'chatMessage',
    type: 'text',
    placeholder: '<text> {option} <optional text>',
    isBasic: true,
    gridSize: 3,
  },
  { label: 'Chat Type', identifier: 'chatType', options: CHAT_TYPE_OPTIONS, gridSize: 3 },
  { label: 'Twitter Type', identifier: 'twitterType', options: TWITTER_TYPE_OPTIONS, gridSize: 3 },
  { label: 'Question', identifier: 'question', type: 'text', isBasic: true, gridSize: 12 },
  { label: 'Question Header', identifier: 'questionHeader', type: 'text', isBasic: true, gridSize: 8 },
  { label: 'Choice', identifier: 'options', isOption: true, gridSize: 12, innerGrid: 12 },
  { label: 'Explanation', identifier: 'explanation', type: 'text', isBasic: true, isMutline: true, gridSize: 12 },
  { label: 'Question Header Image', identifier: 'questionHImage', isImageUploader: true, gridSize: '' },
  { label: 'Generate Image Header', identifier: 'imageHeaderText', type: 'text', isBasic: true, gridSize: 8 },
];

const CHOICE_HANDLER = {
  REGENERATE: 'REGENERATE',
  DELETE: 'DELETE',
};

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 55,
      border: '',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};

const topSearchStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minWidth: '280px',
      border: '',
      marginLeft: "20px"
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};


const AddEditQuestionBank = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [editData, setEditData] = useState({});
  const [nextQuestion, setNextQuestion] = useState(true);
  const [previousQuestion, setPreviousQuestion] = useState(true);
  const [searchQuestion, setSearchQuestion] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [payloadLoading, setPayloadLoading] = useState(false);
  const [nextQuestionRefresh, setNextQuestionRefresh] = useState();
  const [isImageError, setIsImageError] = useState([]);
  const [generateLoading, setGenerateLoading] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [imageData, setImageData] = useState();
  const [imageCount, setImageCount] = useState(0);
  const [selectedQuestionImage, setSelectedQuestionImage] = useState();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);

  useEffect(() => {
    listApi(APIs.QUESTION_BANK_LIST, { id })
      .then((res) => {
        let choiceOptions = [];
        let isImageChoice = false;
        if (res?.data?.nameOptions?.length > 0) {
          choiceOptions = res?.data?.nameOptions?.map((choice, index) => ({
            choice,
            imageChoice: res?.data?.options?.[index],
            selectedImage: '',
            choicesOptions: [],
            isImageGenerated: false,
            imagePageCount: 0,
          }));
          isImageChoice = true;
        } else {
          choiceOptions = res?.data?.options.map((choice, index) => ({ choice }));
        }
        if (res?.nextId?.id) setNextQuestion(res?.nextId?.id);
        if (res?.previousId?.id) setPreviousQuestion(res?.previousId?.id);

        setEditData({
          type: TYPE_OPTIONS.find((obj, i) => obj?.value === res?.data?.type),
          questionType: QUESTION_TYPE.find((obj, i) => obj?.value === res?.data?.questionType),
          answerCalculated: ANSWERE_TYPE.find((obj, i) => obj?.value === res?.data?.answerCalculated),
          type2: TYPE_TWO.find((obj, i) => obj?.value === res?.data?.type2),
          twitterType:
            TWITTER_TYPE_OPTIONS.find((obj, i) => obj?.value === res?.data?.twitterType) || TWITTER_TYPE_OPTIONS[0],
          brand: res?.data?.brand ? res?.data?.brand : '',
          category: res?.data?.category ? res?.data?.category : '',
          subCategory: res?.data?.subCategory ? res?.data?.subCategory : '',
          topic: res?.data?.topic ? res?.data?.topic : '',
          subtopic: res?.data?.subtopic ? res?.data?.subtopic : '',
          timer: res?.data?.timer ? res?.data?.timer : '',
          timerPoints: res?.data?.timerPoints ? res?.data?.timerPoints : '',
          points: res?.data?.points ? res?.data?.points : '',
          chatType: CHAT_TYPE_OPTIONS.find((obj, i) => obj?.value === res?.data?.chatType) || CHAT_TYPE_OPTIONS[0],
          chatMessage: res?.data?.chatMessage ? res?.data?.chatMessage : '',
          difficultyLevel: res?.data?.difficultyLevel ? res?.data?.difficultyLevel : 'beginner',
          questionHeader: res?.data?.questionHeader ? res?.data?.questionHeader : '',
          releasePeriod: res?.data?.releasePeriod ? res?.data?.releasePeriod : '',
          generateImageAuto: res?.data?.generateImageAuto ? true : false,
          isVerified: res?.data?.isVerified ? true : false,
          explanation: res?.data?.explanation ? res?.data?.explanation : '',
          question: res?.data?.question ? res?.data?.question : '',
          source: res?.data?.source ? res?.data?.source : '',
          sourceName: res?.data?.sourceName ? res?.data?.sourceName : '',
          options: choiceOptions,
          questionHImage: res?.data?.questionHImage,
          isImageChoice,
          correct: res?.data?.correct ? res?.data?.correct.map((item) => item - 1) : [],
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [payloadLoading,nextQuestionRefresh]);

  /* Handler submit */

  const handleFormSubmit = (payload) => {
    setIsImageError([]);
    let hasError = false;

    payload?.options?.map((obj, outerIndex) => {
      if (obj?.isImageGenerated) {
        if (obj?.selectedImage === '') {
          setIsImageError((prev) => [...prev, outerIndex]);
          hasError = true;
        }
      }
    });
    if (!hasError) {
      if (payload?.isImageChoice) {
        payload.nameOptions = payload?.options?.map((obj, i) => {
          return obj.choice;
        });
        payload.options = payload?.options?.map((obj, i) => {
          if (obj.isImageGenerated) {
            return obj?.selectedImage;
          } else {
            return obj?.imageChoice;
          }
        });
      } else {
        payload.options = payload?.options?.map((obj, i) => {
          return obj.choice;
        });
      }

      setPayloadLoading(true);

      if (payload.timer === '') payload.timer = '0';
      if (payload.timerPoints === '') payload.timerPoints = '0';
      delete payload.imageHeaderText;

      if ('twitterType' in payload) {
        payload.twitterType = payload?.twitterType?.value === 'NA' ? null : payload?.twitterType?.value;
      }

      if ('chatType' in payload) {
        payload.chatType = payload?.chatType?.value === 'NA' ? null : payload?.chatType?.value;
      }
      const increment = payload?.correct?.map((it) => it + 1);

      const payloadObj = {
        ...payload,
        type: payload?.type?.value,
        type2: payload?.type2?.value,
        questionType: payload?.questionType?.value,
        answerCalculated: payload?.answerCalculated?.value,
        id,
        correct: increment,
      };

      addEditApi(APIs.ADD_GENERATED_QUESTION, { question: payloadObj })
        .then((res) => {
          toast.success('Updated Successfully');
          // navigate(-1);
        })
        .finally(() => setPayloadLoading(false));
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editData,
    validationSchema: questionEditSchema,
    onSubmit: handleFormSubmit,
  });

  const { errors, touched, values, handleSubmit, setFieldValue } = formik;

  /*Image Upload Handler */
  const uploadImage = (e) => {
    setImageLoading(true);
    const file = e?.target?.files?.[0];
    const data = new FormData();
    data.append('file', file);

    addEditApi(APIs.UPLOAD_IMAGE, data)
      .then((result) => {
        setFieldValue(`questionHImage`, result?.profileImg);
      })
      .finally(() => setImageLoading(false));
  };

  // confirmation popup
  const onOptioActionHandler = (innerIndex, identifier, choice) => {
    if (identifier === CHOICE_HANDLER.DELETE) {
      confirmAlert({
        title: 'Alert',
        message: 'Are you sure want to Delete this option?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              // setFieldValue(
              //   'correct',
              //   values?.correct?.filter((str, i) => innerIndex !== i)
              // );
              setFieldValue(
                'options',
                values?.options?.filter((str, i) => innerIndex !== i)
              );
              setIsImageError([]);
            },
          },
          {
            label: 'No',
          },
        ],
      });
    }
    if (identifier === CHOICE_HANDLER.REGENERATE) {
      setGenerateLoading(innerIndex);
      listApi(APIs.GENERATE_IMAGE_AI, { options: [choice] }).then((res) => {
        let imageArray = res?.data?.[choice]?.map((obj, i) => {
          return obj.thumbnail;
        });
        setFieldValue(`options.${[innerIndex]}.choicesOptions`, imageArray);
        setFieldValue(`options.${[innerIndex]}.isImageGenerated`, true);
        setFieldValue(`options.${[innerIndex]}.imagePageCount`, 0);
        setGenerateLoading('');
      });
    }
  };

  //generate question handler
  const GenerateButtonHandler = (questionHeader, identifier) => {
    if (identifier === GENERATED_CONTANT.HEADER_IMAGE) {
      setSubmitLoading(true);
      listApi(APIs.GENERATE_IMAGE_AI, { headerText: questionHeader }).then((res) => {
        setImageData(res?.data);
        toast.success('Images Generated Successfully');
        setSubmitLoading(false);
      });
    }
  };

  //function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  };
  const ImageContainer = ({ img, isSelect, selectedImage, innerIndex }) => {
    return (
      <div
        style={{
          width: '100px',
          padding: '1px',
          border: `3px solid ${selectedImage === img && isSelect ? '#2065d2' : 'white'}`,
          minHeight: '100px',
          maxHeight: '100px',
          marginLeft: '10px',
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        <img
          src={img}
          style={{
            width: '100px',
            height: '92px',
          }}
          onClick={(e) => {
            if (isSelect) {
              e.preventDefault();
              setFieldValue(`options.${[innerIndex]}.selectedImage`, img);
              if (isImageError.includes(innerIndex)) {
                let newError = isImageError?.filter((indexValue, i) => indexValue !== innerIndex);
                setIsImageError(newError);
              }
            }
          }}
        />
        <div
          style={{
            width: '30px',
            height: '30px',
            position: 'absolute',
            top: '1px',
            right: '1px',
            padding: '2px',
            backgroundColor: '#00000060',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FullscreenIcon onClick={() => showFullImage(img)} style={{ fill: '#ffffff' }} />
        </div>
      </div>
    );
  };

  const promiseQuestionOptions = (inputValue, resolve) => {
    listApi(APIs.QUESTION_BANK_LIST, { search: inputValue, fromJsonCreate: true }).then((result) => {
      const filter = result?.data.map((item) => {
        return getLabelValue(item);
      });
      resolve(filter);
    });
  };

  function getLabelValue(item) {
    return {
      label: (
        <div className="optionStyles">
          <img src={item?.questionHImage} height="30px" width="30px" />
          <div className="selectText">
            {item?.id}
          </div>
        </div>
      ),
      value: item?.id,
    };
  }

  const loadQuestionOptions = debounce(promiseQuestionOptions, 300);

  function questionNavigate(questionId) {
    navigate(`/edit-question/${questionId}`);
    setNextQuestionRefresh(prev => !prev);
    setLoading(prev => !prev);
  }

  const NextPreviousQuestion = ({position='auto'}) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'end' }}>
      <Tooltip title="Previous Question">
        <Button
          variant='contained'
          type='button'
          startIcon=<SkipPreviousSharpIcon />
          style={BUTTTON_COSNTANT[0]?.style}
          // disabled={previousQuestion }
          onClick={() => questionNavigate(previousQuestion)}
        >
        </Button>
      </Tooltip>
      <AsyncSelect
        cacheOptions
        placeholder="Search Question"
        styles={topSearchStyles}
        loadOptions={loadQuestionOptions}
        name={'idSearch'}
        isClearable
        value={searchQuestion}
        onChange={(data) => {
          setSearchQuestion(data?.value ? data : null);
          questionNavigate(data?.value || id)
        }}
        menuPlacement={position}
      />
      <Tooltip title="Next Question">
        <Button
          variant='contained'
          type='button'
          startIcon=<SkipNextSharpIcon />
          style={BUTTTON_COSNTANT[0]?.style}
          // disabled={nextQuestion}
          onClick={() => questionNavigate(nextQuestion)}
        >
        </Button>
      </Tooltip>
    </div>
    );
  };

  return (
    <Card style={{ padding: '20px' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Edit Question - {id}
        </Typography>
        <NextPreviousQuestion />
      </Stack>
      {loading ? (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2} padding={2} style={{ paddingTop: '0px', paddingBottom: '20px' }}>
              {COMMONN_FIELD.map((field, fieldIndex) => {
                return (
                  <Grid item xs={12} md={field.gridSize} key={fieldIndex}>
                    {field?.isBasic ? (
                      <TextField
                        fullWidth
                        key={fieldIndex}
                        multiline={field?.isMutline}
                        rows={5}
                        // maxRows={4}
                        label={field.label}
                        type={field.type}
                        name={field.identifier}
                        value={values?.[field.identifier]}
                        placeholder={field?.placeholder}
                        defaultValue={values?.[field.identifier]}
                        onChange={(e) => {
                          setFieldValue([field.identifier], e.target.value);
                        }}
                        error={Boolean(touched?.[field.identifier] && errors?.[field.identifier])}
                        helperText={
                          errors?.[field.identifier] ? (
                            <div style={{ color: 'red' }}>{errors?.[field.identifier]}</div>
                          ) : (
                            <div style={{ visibility: 'hidden' }}>hidden</div>
                          )
                        }
                      />
                    ) : field?.isOption ? (
                      <>
                        {values?.options?.length < 4 && (
                          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                            <AddCircleOutlineIcon
                              style={{
                                marginLeft: '5px',
                                marginRight: '5px',
                                color: 'red',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                let defaultVal = {};
                                if (values?.isImageChoice) {
                                  defaultVal = {
                                    choice: '',
                                    imageChoice: '',
                                    selectedImage: '',
                                    isImageGenerated: true,
                                  };
                                } else {
                                  defaultVal = { choice: '' };
                                }
                                setFieldValue('options', [...values?.options, defaultVal]);
                              }}
                            />
                            <div style={{ fontWeight: 'bold', marginTop: '2px' }}>Add Choice</div>
                          </div>
                        )}

                        <Grid
                          container
                          xs={12}
                          md={field.gridSize}
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          {values?.options?.map((choice, innerIndex) => {
                            const handleCheckboxChange = (innerIndex, e) => {
                              let newChecked = [...(formik.values.correct || [])];
                              console.log(innerIndex, e.target.checked);

                              if (e.target.checked) {
                                if (newChecked.includes(innerIndex)) {
                                  newChecked = newChecked.filter((it) => it !== innerIndex);
                                } else {
                                  newChecked.push(innerIndex);
                                }
                              }
                              else newChecked = newChecked.filter((it) => it !== innerIndex);

                              formik.setFieldValue('correct', newChecked);
                            };

                            return (
                              <Grid container key={innerIndex} spacing={2} padding={1} alignItems="center">
                                {/*1. CHECKBOX BUTTON  */}
                                <Grid item xs={1}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value={innerIndex}
                                        name={innerIndex}
                                        checked={
                                          Array.isArray(formik.values.correct) &&
                                          formik.values.correct.includes(innerIndex)
                                        }
                                        onChange={(e) => {
                                          handleCheckboxChange(innerIndex, e);
                                        }}
                                      />
                                    }
                                    label=""
                                  />
                                </Grid>

                                {/*2. TEXTFIELD */}
                                <Grid item xs={2}>
                                  <TextField
                                    fullWidth
                                    label={`Choice ${innerIndex + 1}`}
                                    type="text"
                                    value={choice?.choice}
                                    onChange={(e) => setFieldValue(`options.${innerIndex}.choice`, e.target.value)}
                                    error={Boolean(
                                      touched?.options?.[innerIndex]?.choice && errors?.options?.[innerIndex]?.choice
                                    )}
                                    helperText={
                                      errors?.options?.[innerIndex]?.choice ? (
                                        <div style={{ color: 'red' }}>{errors?.options?.[innerIndex]?.choice}</div>
                                      ) : (
                                        <div style={{ visibility: 'hidden' }}>hidden</div>
                                      )
                                    }
                                  />
                                </Grid>

                                {/*3. ALREADY IMAGE */}
                                <Grid item xs={2}>
                                  {choice?.imageChoice && values?.isImageChoice && (
                                    <ImageContainer img={choice?.imageChoice} isSelect={false} />
                                  )}
                                </Grid>

                                {/*4. RELOAD ICON */}
                                <Grid item xs={1}>
                                  {(values?.isImageChoice || values?.type2?.value === 'IMAGE_TYPE') && (
                                    <Tooltip title="Generate/Regenerate Image">
                                      <ReplayIcon
                                        style={{
                                          marginLeft: '10px',
                                          marginTop: '15px',
                                          fontSize: '20px',
                                          cursor: 'pointer',
                                          visibility: generateLoading ? 'hidden' : 'visible',
                                        }}
                                        onClick={() =>
                                          onOptioActionHandler(innerIndex, CHOICE_HANDLER.REGENERATE, choice?.choice)
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </Grid>

                                {/*5. GENERATED IMAGES */}
                                <Grid item xs={5}>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {generateLoading === innerIndex && (
                                      <Typography variant="subtitle2">Generating Images...</Typography>
                                    )}
                                    {isImageError?.includes(innerIndex) ? (
                                      <div style={{ color: 'red', fontSize: '11px' }}>Select Image</div>
                                    ) : (
                                      <div style={{ color: 'red', visibility: 'hidden', fontSize: '11px' }}>Error</div>
                                    )}
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                      {choice?.imagePageCount >= 1 && (
                                        <Tooltip title="Previous images">
                                          <ReadMoreIcon
                                            style={{
                                              marginLeft: '10px',
                                              fontSize: '30px',
                                              cursor: 'pointer',
                                              color: 'red',
                                              transform: 'scaleX(-1)',
                                            }}
                                            onClick={() => {
                                              setFieldValue(
                                                `options.${innerIndex}.imagePageCount`,
                                                choice?.imagePageCount - 1
                                              );
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                      {choice?.choicesOptions?.length > 0 &&
                                        choice?.choicesOptions
                                          ?.slice(choice?.imagePageCount * 4, choice?.imagePageCount * 4 + 4)
                                          .map((optionImage, i) => (
                                            <Grid item xs={7} key={i}>
                                              <ImageContainer
                                                img={optionImage}
                                                isSelect={true}
                                                selectedImage={choice?.selectedImage}
                                                innerIndex={innerIndex}
                                              />
                                            </Grid>
                                          ))}
                                      {choice?.imagePageCount >= 0 && choice?.isImageGenerated && (
                                        <Tooltip title="Show more images">
                                          <ReadMoreIcon
                                            style={{
                                              marginLeft: '10px',
                                              fontSize: '30px',
                                              cursor: 'pointer',
                                              color: 'red',
                                            }}
                                            onClick={() => {
                                              setFieldValue(
                                                `options.${innerIndex}.imagePageCount`,
                                                choice?.imagePageCount + 1
                                              );
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </div>
                                  </div>
                                </Grid>

                                {/* DELETE ICON */}
                                <Grid item xs={1} display="flex" justifyContent="end">
                                  {values?.options?.length > 2 && (
                                    <CloseIcon
                                      style={{
                                        visibility: generateLoading ? 'hidden' : 'visible',
                                        marginLeft: '10px',
                                        marginTop: '15px',
                                        fontSize: '20px',
                                        cursor: 'pointer',
                                        color: 'red',
                                      }}
                                      onClick={() => onOptioActionHandler(innerIndex, CHOICE_HANDLER.DELETE)}
                                    />
                                  )}
                                </Grid>
                              </Grid>
                            );
                          })}
                          {errors.correct && touched.correct && (
                            <div style={{ color: 'red', marginBottom: '20px' }}>{errors.correct}</div>
                          )}
                        </Grid>
                      </>
                    ) : field?.isImageUploader ? (
                      <Grid item xs={12} md={12}>
                        <Typography variant="subtitle2" gutterBottom>
                          Question Header Image
                        </Typography>

                        <Avatar
                          alt={'N'}
                          src={values?.questionHImage}
                          sx={{ width: 100, height: 100, marginBottom: '10px' }}
                          onClick={() => showFullImage(values?.questionHImage)}
                        />
                        <input type="file" accept="image/*" onChange={(e) => uploadImage(e)} disabled={imageLoading} />
                      </Grid>
                    ) : field?.isSwitch ? (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values?.[field.identifier]}
                            onChange={(event) => setFieldValue([field.identifier], event.target.checked)}
                          />
                        }
                        label={field.label}
                      />
                    ) : (
                      <>
                        <label style={{ fontSize: '14px' }}>{field.label}</label>
                        <Select
                          cacheOptions
                          defaultOptions
                          placeholder={field.label}
                          styles={customStyles}
                          options={field.options}
                          name={field.identifier}
                          value={values?.[field.identifier]}
                          getOptionLabel={(v) => v?.label}
                          getOptionValue={(v) => v?.value}
                          onChange={(data) => setFieldValue([field.identifier], data)}
                        />
                        {errors?.[field.identifier] ? (
                          <div style={{ color: 'red', fontSize: '0.75rem' }}>{errors?.[field.identifier]}</div>
                        ) : (
                          <div style={{ visibility: 'hidden' }}>hidden</div>
                        )}
                      </>
                    )}
                  </Grid>
                );
              })}
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  onClick={() => {
                    GenerateButtonHandler(values?.imageHeaderText, GENERATED_CONTANT.HEADER_IMAGE);
                  }}
                  disabled={!values?.imageHeaderText}
                  style={{ marginLeft: '10px' }}
                >
                  {submitLoading ? 'Generating...' : 'Generate'}
                </Button>

                <Button
                  variant="contained"
                  onClick={(e) => {
                    setImageCount(0);
                    setImageData('');
                    setFieldValue(`imageHeaderText`, '');
                  }}
                  style={{ marginLeft: '10px' }}
                >
                  {'Reset/Cancel'}
                </Button>
              </Grid>
            </Grid>

            {imageData?.length > 0 && (
              // SHOWCASE OF HEADER IMAGES
              <div>
                <div style={{ display: 'flex', alignItems: 'center', margin: '5px 0px' }}>
                  {imageCount >= 1 && (
                    <Tooltip title="Previous images">
                      <ReadMoreIcon
                        style={{
                          marginLeft: '10px',
                          fontSize: '30px',
                          cursor: 'pointer',
                          color: 'red',
                          transform: 'scaleX(-1)',
                        }}
                        onClick={() => {
                          setImageCount(imageCount - 1);
                        }}
                      />
                    </Tooltip>
                  )}
                  {imageData?.slice(imageCount * 4, imageCount * 4 + 4)?.map((img, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: '100px',
                          padding: '1px',
                          border: `3px solid ${selectedQuestionImage === img ? '#2065d2' : 'white'}`,
                          height: '100px',
                          marginLeft: '10px',
                          cursor: 'pointer',
                          position: 'relative',
                        }}
                      >
                        <img
                          src={img?.original}
                          style={{
                            width: '100px',
                            height: '92px',
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedQuestionImage(img);
                            setFieldValue('questionHImage', img?.thumbnail);
                          }}
                        />
                        <div
                          style={{
                            width: '30px',
                            height: '30px',
                            position: 'absolute',
                            top: '1px',
                            right: '1px',
                            padding: '2px',
                            backgroundColor: '#00000060',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <FullscreenIcon onClick={() => showFullImage(img?.thumbnail)} style={{ fill: '#ffffff' }} />
                        </div>
                      </div>
                    );
                  })}
                  <Tooltip title="Show more images">
                    <ReadMoreIcon
                      style={{
                        marginLeft: '10px',
                        fontSize: '30px',
                        cursor: 'pointer',
                        color: 'red',
                      }}
                      onClick={() => {
                        setImageCount(imageCount + 1);
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            )}
        
            <div style={{ display: 'flex', justifyContent: 'end' }}>
            <NextPreviousQuestion position='top' />
              {BUTTTON_COSNTANT.map((btnObj, buttonIndex) => {
                return (
                  <Button
                    key={buttonIndex}
                    variant={btnObj?.variant}
                    type={btnObj?.type}
                    startIcon={btnObj?.startIcon}
                    style={btnObj?.style}
                    disabled={payloadLoading}
                    onClick={() => {
                      if (btnObj.identifier === 1) {
                        navigate(-1);
                      }
                    }}
                  >
                    {btnObj.label}
                  </Button>
                );
              })}
            </div>
          </Form>
        </FormikProvider>
      )}
      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
    </Card>
  );
};

export default AddEditQuestionBank;
