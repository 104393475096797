import React, { useEffect, useState, useMemo } from 'react';
import './index.css';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  CircularProgress,
  Skeleton,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { listApi } from 'src/apis/admin';
import { APIs } from 'src/apis/client';
import QuestionStatsPieChart from '../statsPieChart';
import { pieChartColors, statsHeaderOptions } from 'src/contants/contants';


const ContestStats = ({ closeModal, selectedContestId }) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState();
  const [timeInterval, setTimeInterval] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const noDataHandler = () => {
    setTimeout(() => {
      setTimeInterval(true);
    }, 2000);
  };

  ///Getting Particular Contest stats List/////////
  useMemo(() => {
    setLoading(true);
    listApi(APIs.CONTEST_STATS, { gameId: selectedContestId?.gameIds[0]?.id, contestId: selectedContestId?.id })
      .then((result) => {
        setList(result?.data);
      })
      .finally(setLoading(false));
  }, [refresh]);

  const countTotal = (options) => {
    let sum = 0;
    if (options?.length > 0) {
      options?.map((item) => {
        sum += Object.values(item)[0]
      })
    }
    return sum
  }

  return (
    <div className="modalWinnerContainer">
      <Container className="modalInnerContainer">
        <div className="modalheadingWinnerrow">
          <Typography variant="h4" gutterBottom>
            {selectedContestId?.id ? selectedContestId?.name : <Skeleton variant="rectangular" width={310} height={40} />} Stats
          </Typography>
          <Typography variant="h4" gutterBottom>
            <Button onClick={closeModal} style={{ marginRight: '10px' }}>
              Cancel
            </Button>
          </Typography>
        </div>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 500 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {statsHeaderOptions.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {!!list?.length > 0 ? (
                    <TableBody>
                      {list?.map((row, index) => {
                        const {
                          id, question, questionHImage, options, correct, nameOptions,
                          countOptions, choices
                        } = row

                        return (
                          <TableRow
                            hover
                            key={index}
                            tabIndex={-1}
                            role="checkbox"
                          >

                            <TableCell align="center">{id}</TableCell>
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={id} src={questionHImage} />
                                <Typography variant="subtitle2" break-spaces style={{ width: '120px' }}>
                                  {question}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              {options?.map((option, index) => (
                                <p style={{ width: 270, marginBottom: 5 }}>
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    {nameOptions && nameOptions.length ?
                                      (
                                        <Typography variant="subtitle2" break-spaces>
                                          {nameOptions[index]} : {(Object.values(option)[0]).toFixed(1)}%
                                        </Typography>
                                      ) : (
                                        <Typography variant="subtitle2" break-spaces>
                                          {Object.keys(option)[0]} : {(Object.values(option)[0]).toFixed(1)}%
                                        </Typography>
                                      )
                                    }
                                  </Stack>
                                </p>
                              ))}
                            </TableCell>
                            <TableCell align="left">
                              <QuestionStatsPieChart
                                title="Stats"
                                chartData={options.map((option, index) => {
                                  return { label: nameOptions && nameOptions.length ? nameOptions[index] : Object.keys(option)[0], value: parseFloat((Object.values(option)[0]).toFixed(1)) };
                                })}
                                chartColors={pieChartColors}
                              />
                            </TableCell>
                            <TableCell align="left">
                              {countOptions?.map((option, index) => (
                                <Typography variant="subtitle2">
                                  <li style={{ width: 200, marginBottom: 5, color: pieChartColors[index] }}>
                                    {nameOptions && nameOptions.length ? nameOptions[index] : Object.keys(option)[0]} : {Object.values(option)[0]}
                                  </li>
                                </Typography>
                              ))}
                              {countOptions?.length > 0 &&
                                <Typography variant="subtitle2" break-spaces>
                                  Total : {countTotal(countOptions)}
                                </Typography>
                              }
                            </TableCell>
                            <TableCell sx={{ minWidth: 300 }}>
                              <Typography variant="subtitle2">
                                {correct && correct?.map(index => nameOptions && nameOptions.length ? nameOptions[index - 1] : choices[index - 1])}
                                <p>({correct && correct.join(', ')})</p>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    ''
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!!list?.length == 0 && (
          <Typography variant="subtitle2" align="center" style={{ marginTop: '20px', marginBottom: '90px' }}>
            {noDataHandler()}
            {!timeInterval ? 'Loading...' : 'No Data Found'}
          </Typography>
        )}
        <div className="modalWinnnerFooterContainer">
          <Typography variant="h4" gutterBottom>
            <Button onClick={closeModal} style={{ marginRight: '10px' }}>
              Cancel
            </Button>
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default ContestStats;
